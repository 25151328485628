import React from "react";
import { Row, Col } from "react-bootstrap";
import AdminLogout from "../../AdminLogout/js/AdminLogout";
import "../scss/Header.scss";
import toyotalogo from "./../../../assets/Toyota-logo.svg";
import lexuslogo from "./../../../assets/lexus-logo-updated.png";

// Header Component
function Header(props) {
  return (
    <div className="header">
      <Row className="header-wrap">
        <Col>
          <div className="logo-wrap">
            <a
              href="https://www.toyota.com/"
              className="toyota-logo"
              rel="noreferrer"
              target="_blank"
            >
              <img src={toyotalogo} alt="Toyota-logo" />
            </a>
            <a
              href="https://www.lexus.com/"
              className="lexus-logo"
              rel="noreferrer"
              target="_blank"
            >
              <img src={lexuslogo} alt="Lexus Logo" />
            </a>
          </div>
        </Col>
        <Col>
          <AdminLogout />
        </Col>
      </Row>
    </div>
  );
}
export default Header;
