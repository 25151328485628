import React, { useState } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import {
  editOrDelete,
  datevalue,
  propcondition,
  HelpSection,
  CategorySec,
  EnableEmailSec,
  DateSec,
  CommentSec,
  FinalSelection,
  DefaultCommunicationModes,
  level1props,
  levelcommonprops,
  jsondata,
  ActionButtonSec,
  onlyAddconditon,
  onlyDeleteconditon,
  apicall,
  onlyView,
  deleteOrView,
  DescriptionSec,
  jsondataForPreference,
  CSTNote,
  IsExpandable,
  ExpandableId,
} from "../../CommonBlocks/js/CommonBlock";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMsal } from "@azure/msal-react";
toast.configure();

// MOC is available if till level 3 if its permission, only in level 1 if its Prefernce tab
const conditionForMocCheck = (ispref, level) => {
  return (!ispref && level <= 3) || (ispref && level === 1);
};
//Append Final level prop
const checkFinalLevel = (finalLevel, finaldata) => {
  if (finalLevel !== undefined) {
    finaldata.adminMetaData = {
      ...finaldata.adminMetaData,
      isFinalLevel: finalLevel,
    };
  }
};

function iscondcheck(type, isreject, validity) {
  return type === "reject" ? isreject && validity : true;
}
// Main Approve and Reject Component
function ApproveRejectScreen(props) {
  const { instance, accounts } = useMsal();
  const [isReject, setIsReject] = useState(false);
  const id = props.category.id;
  const conditionForMoc = conditionForMocCheck(
    props.category.isPreference,
    props.level
  );
  let categoryData = {
    ...propcondition(props),
  };
  if (props.level === 1) {
    categoryData = { ...categoryData, id };
  }
  if (conditionForMoc) {
    categoryData = { ...level1props(props), ...categoryData };
  } else {
    categoryData = { ...levelcommonprops(props), ...categoryData };
  }
  const [requestApproveData, setRequestApproveData] = useState(categoryData);
  const handleRequestChange = (e) => {
    setRequestApproveData({
      ...requestApproveData,
      [e.target.name]: e.target.value,
    });
  };

  const onAction = (actiontype) => {
    actiontype === "reject" && setIsReject(true);
    var form = document.getElementById("form1");
    const conditioncheck = iscondcheck(
      actiontype,
      isReject,
      form.reportValidity()
    );
    if (conditioncheck) {
      getParsForApi("edit", actiontype);
    }
  };

  // Get parameters and post based on ispref flag
  const getParsForApi = async (func, action) => {
    const token = await tokenAndRolesCapture(instance, accounts);
    if(token != undefined){
    const username = token.idTokenClaims.name;
    const id = props.category.id;
    const leveltype = "category";
    const ispref = props.category.isPreference;
    const comments = props.category.comments;
    const finaldata = ispref
      ? jsondataForPreference({
          ...requestApproveData,
          comments,
          func,
          action,
          username,
          id,
        })
      : jsondata({
          ...requestApproveData,
          comments,
          func,
          action,
          username,
          id,
        });
    // Append is Final level for Permission
    checkFinalLevel(props.category.isFinalLevel, finaldata);
    apicall({
      finaldata,
      func,
      onClose: props.onClose,
      notify: props.notify,
      brand: props.brand,
      action,
      leveltype,
      token,
    });
  }
  };
  return (
    <Modal
      className="modalpopup modal-permissionlevel1"
      show={props.show}
      onHide={() => props.onClose()}
    >
      <Modal.Header closeButton>
        <p>Final Approve / Reject</p>
      </Modal.Header>
      <Modal.Body>
        <Form id="form1">
          <Row>
            <Col md={12}>
              <p>Level {props.level}</p>
            </Col>
            <HelpSection pageno={19} />
          </Row>

          <CategorySec
            category={requestApproveData.categoryname}
            status={props.category.status}
            optType={props.optionType}
          />
          <DescriptionSec
            description={requestApproveData.description}
            onlyDelete={deleteOrView(props.optionType)}
          />
          {props.level === 1 && (
            <EnableEmailSec
              altEmail={requestApproveData.enableAlternateEmailId}
              onlyDelete={deleteOrView(props.optionType)}
            />
          )}
          {conditionForMoc && (
            <DefaultCommunicationModes
              email={requestApproveData.email}
              mail={requestApproveData.mail}
              phone={requestApproveData.phone}
              text={requestApproveData.text}
              default={requestApproveData.default}
              onlyDelete={deleteOrView(props.optionType)}
            />
          )}
          <CSTNote />
          <DateSec
            startDate={requestApproveData.startDate}
            endDate={requestApproveData.endDate}
            type={editOrDelete(props.optionType) ? datevalue : "text"}
            onlyDelete={onlyDeleteconditon(props.optionType)}
            onlyView={onlyView(props.optionType)}
            onlyAddconditon={onlyAddconditon(props.optionType)}
          />

          {props.category.isFinalLevel && (
            <FinalSelection
              onlyDelete={true}
              onChecked={props.category.isFinalLevel}
            />
          )}
          {props.category.isExpandable && (
            <IsExpandable
              id={ExpandableId}
              onlyDelete={true}
              onChecked={requestApproveData.isExpandable}
            />
          )}
          <CommentSec
            commentText={requestApproveData.commentText}
            onChange={(e) => handleRequestChange(e)}
            editOrDelete={editOrDelete(props.optionType)}
            onlyDelete={deleteOrView(props.optionType)}
            approve={isReject}
          />
          <ActionButtonSec onAction={onAction} />
        </Form>
      </Modal.Body>
    </Modal>
  );
}
export default ApproveRejectScreen;
