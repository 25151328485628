import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "../scss/ManageComponent.scss";
import statusDetails from "../../CategoriesData/StatusDetails.json";
import AddNewLevels from "../../AddNewLevels/js/AddNewLevels";
import AddPreferenceLevels from "../../AddPreferenceLevels/js/AddPreferenceLevels";
import DetailedViewPage from "../../DetailedViewPage/js/DetailedViewPage";
import { AxiosGet } from "../../AxiosMethods/ApiCalls";
import AddPermissionLevels from "../../AddPermissionLevels/js/AddPermissionLevels";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { useMsal } from "@azure/msal-react";
import { RolePermission } from "./RolePermission";
import DeleteLevel from "../../DeleteLevel/js/DeleteLevel";
import AdminPortalReferenceGuide from "../../../static/ECPC-Reference-Guide.pdf";

// Sub category component
export const SubCategory = (props) => {
  const [subCategory, setSubCategory] = useState(false);
  return (
    <div
      className={
        props.subCategory ? "sub-category btn-sub" : "sub-category btn-nosub"
      }
    >
      <ul>
        <li>
          <button
            className="plusmenu-danger"
            onClick={() => setSubCategory(!subCategory)}
          >
            {props.subCategory &&
              (subCategory ? <FaMinusCircle /> : <FaPlusCircle />)}
          </button>
          <Status status={props.status} />
          {props.subCategoryName}
          <SelectablePopupLevel {...props} />
        </li>

        {props.subCategory &&
          props.subCategory.map((category, index) => {
            return (
              subCategory && (
                <SubCategory
                  key={index}
                  {...category}
                  notify={props.notify}
                  parentCategoryName={props.subCategoryName}
                  isReadAccess={props.isReadAccess}
                  isWriteAccess={props.isWriteAccess}
                />
              )
            );
          })}
      </ul>
    </div>
  );
};

// Adding further levels after level 5 is not possible
// Level 5 is last level
const showPopuponCondition = (props) => {
  if (props.isFinalLevel || props.level === 5) {
    toast.error(`${props.subCategoryName} is final level`);
  } else {
    props.setEnablePopup();
    props.setOptType();
  }
};

// Function to check Write access of the user
const WriteAccessPopup = (props) => {
  const catName =
    props.level === 1 ? props.categoryName : props.subCategoryName;
  return (
    <ul className="selectble-popup">
      {(props.level === 1 || props.isPreference) &&
        props.status !== "Inactive" && (
          <li
            onClick={() =>
              showPopuponCondition({
                isFinalLevel: props.isFinalLevel,
                level: props.level,
                subCategoryName: catName,
                setEnablePopup: () => props.setPrefLevelPopup(true),
                setOptType: () => props.setOptionType("Add"),
              })
            }
          >
            Add New Preference
          </li>
        )}
      {(props.level === 1 || !props.isPreference) &&
        props.status !== "Inactive" && (
          <li
            onClick={() =>
              showPopuponCondition({
                isFinalLevel: props.isFinalLevel,
                level: props.level,
                subCategoryName: catName,
                setEnablePopup: () => props.setLevelPopup(true),
                setOptType: () => props.setOptionType("Add"),
              })
            }
          >
            Add New Permission
          </li>
        )}

      {props.status !== "Inactive" && props.status !== "Pending Approval" && (
        <li
          onClick={() => {
            props.isPreference
              ? props.setPrefLevelPopup(true)
              : props.setLevelPopup(true);
            props.setOptionType("Edit");
          }}
        >
          Modify
        </li>
      )}
      {props.level === 1 && (
        <li onClick={() => props.setShowDetailLevel(true)}>
          View Existing Content
        </li>
      )}
      {props.status === "Draft" && (
        <li
          onClick={() => {
            props.setShowDeleteLevel(true);
          }}
        >
          Delete
        </li>
      )}
    </ul>
  );
};

// On hover drop down based on Role access
function SelectablePopupLevel(props) {
  const [showLevelPopup, setLevelPopup] = React.useState(false);
  const [showPrefLevelPopup, setPrefLevelPopup] = React.useState(false);
  const [optionType, setOptionType] = React.useState("Add");
  const [showDetailLevel, setShowDetailLevel] = React.useState(false);
  const [showDeleteLevel, setShowDeleteLevel] = React.useState(false);
  return (
    <div className="dropdown-content">
      {props.isWriteAccess ? (
        <WriteAccessPopup
          {...props}
          setPrefLevelPopup={setPrefLevelPopup}
          setOptionType={setOptionType}
          setLevelPopup={setLevelPopup}
          setShowDetailLevel={setShowDetailLevel}
          setShowDeleteLevel={setShowDeleteLevel}
        />
      ) : (
        props.level === 1 && (
          <ul className="selectble-popup">
            <li onClick={() => setShowDetailLevel(true)}>
              View Existing Content
            </li>
          </ul>
        )
      )}

      {showLevelPopup && (
        <AddPermissionLevels
          show={showLevelPopup}
          onClose={() => setLevelPopup(false)}
          category={props}
          optionType={optionType}
          brand={props.brand}
          notify={props.notify}
          level={optionType === "Add" ? props.level + 1 : props.level}
        />
      )}
      {showPrefLevelPopup && (
        <AddPreferenceLevels
          show={showPrefLevelPopup}
          onClose={() => setPrefLevelPopup(false)}
          category={props}
          optionType={optionType}
          brand={props.brand}
          notify={props.notify}
          level={optionType === "Add" ? props.level + 1 : props.level}
        />
      )}
      {showDetailLevel && (
        <DetailedViewPage
          category={props}
          brand={props.brand}
          show={showDetailLevel}
          onClose={() => setShowDetailLevel(false)}
          notify={props.notify}
        />
      )}
      {showDeleteLevel && (
        <DeleteLevel
          category={props}
          brand={props.brand}
          show={showDeleteLevel}
          onClose={() => setShowDeleteLevel(false)}
          notify={props.notify}
        />
      )}
    </div>
  );
}
export function MainCategory({
  category,
  brand,
  notify,
  isReadAccess,
  isWriteAccess,
}) {
  const [SubC1, setSubC1] = useState(false);

  return (
    <div className="categories-list">
      <div
        className={
          category.subCategory ? "heading btn-sub" : "heading btn-nosub"
        }
      >
        <button className="plusmenu-danger" onClick={() => setSubC1(!SubC1)}>
          {category.subCategory &&
            (SubC1 ? <FaMinusCircle /> : <FaPlusCircle />)}
        </button>
        <span className="heading-text">
          <Status status={category.status} />
          {category.categoryName}
        </span>
        <SelectablePopupLevel
          {...category}
          brand={brand}
          parentCategoryName={category.categoryName}
          notify={notify}
          isReadAccess={isReadAccess}
          isWriteAccess={isWriteAccess}
        />
      </div>

      {category.subCategory &&
        category.subCategory.map((subCateogry, pos) => {
          return (
            SubC1 && (
              <SubCategory
                key={pos}
                {...subCateogry}
                parentCategoryName={category.categoryName}
                brand={brand}
                notify={notify}
                isReadAccess={isReadAccess}
                isWriteAccess={isWriteAccess}
              />
            )
          );
        })}
    </div>
  );
}

// Manage Prefernece page component
function ManageComponent(props) {
  const [showLevel1, SetLevel1] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [categorydata, setCategoriesData] = useState([]);
  const { instance, accounts } = useMsal();
  const [isReadAccess, setIsReadAccess] = useState(false);
  const [isWriteAccess, setIsWriteAccess] = useState(false);
  const [iserror, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Looks like something went wrong! Please refresh!"
  );
  useEffect(() => {
    getApiCall();
  }, [props.brand, props.type]);
  async function getApiCall() {
    const token = await tokenAndRolesCapture(instance, accounts);
    if(token != undefined){
    const roles = token.idTokenClaims.roles;
    if (roles) {
      const roleWriteAccess = roles.some(
        (role) =>
          (RolePermission[role].brand === "both" ||
            RolePermission[role].brand === props.brand.toLowerCase()) &&
          RolePermission[role].write
      );
      const roleReadAccess = roles.some(
        (role) =>
          (RolePermission[role].brand === "both" ||
            RolePermission[role].brand === props.brand.toLowerCase()) &&
          RolePermission[role].read
      );
      setIsWriteAccess(roleWriteAccess);
      setIsReadAccess(roleReadAccess);
    }
    setLoading(true);
    const getDataApi = AxiosGet(
      {
        brand: props.brand,
        type: props.type,
      },
      token
    );
    getDataApi.then(
      (result) => {
        setLoading(false);
        setCategoriesData(result.data.data);
        setError(false);
      },
      (error) => {
        setLoading(false);
        setCategoriesData([]);
        setErrorMessage(
          error.response.data.message.error.messages[0].description
        );
        setError(true);
      }
    );
   }
  }
  const notify = (res, resType) => {
    if (resType === "success") {
      toast.success(res);
      getApiCall();
    } else {
      toast.error(res);
    }
  };

  return (
    <div className="manage-component" id="manage-permission">
      <div className="sub-head">
        <p>Permissions/Preferences</p>
      </div>
      <div className="manage-body">
        <ToastContainer />
        <div className="status-codes">
          <ul>
            {statusDetails.map((status, index) => {
              return (
                <li key={index}>
                  <span
                    className="status-roundend"
                    style={{ backgroundColor: status.colorCode }}
                  ></span>
                  {status.status}
                </li>
              );
            })}
          </ul>
        </div>

        <p className="reference-guide">
          <a href={AdminPortalReferenceGuide} target="_blank" rel="noreferrer">
            Click Here
          </a>{" "}
          for reference guide
        </p>
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            if (isWriteAccess) {
              SetLevel1(true);
            }
          }}
          disabled={!isWriteAccess}
        >
          Create New Category
        </Button>
        <p className="info-text">
          To edit/delete existing category or subcategory hover on item
        </p>
        {iserror && !isLoading && (
          <p className="error-message">{errorMessage}</p>
        )}
        {isLoading
          ? "Loading...."
          : categorydata &&
            categorydata.map((category, index) => {
              return (
                <MainCategory
                  category={category}
                  key={index}
                  brand={props.brand}
                  notify={notify}
                  isReadAccess={isReadAccess}
                  isWriteAccess={isWriteAccess}
                />
              );
            })}
      </div>

      {showLevel1 && (
        <AddNewLevels
          show={showLevel1}
          onClose={() => SetLevel1(false)}
          brand={props.brand}
          notify={notify}
          rankForLevelOne={Math.max(...categorydata.map((d) => d.rank))}
        />
      )}
    </div>
  );
}

// Function to map Status of the records after creation
function Status(props) {
  let bgColor = "";
  statusDetails.map((status) => {
    if (status.status === props.status) {
      bgColor = status.colorCode;
    }
  });
  return (
    <span
      className="status-roundend"
      style={{ backgroundColor: bgColor }}
    ></span>
  );
}

export default ManageComponent;
