import React, { useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import { paginate } from "../../DashboardComponent/js/DashboardComponent";
import { scrollToTop } from "../../CommonBlocks/js/CommonBlock";

// Pagination Implemented
export const Paginated = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    state,
    gotoPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: false,
    },
    usePagination
  );
  const { pageIndex } = state;
  const [pages, setPages] = useState([]);
  const recordtext = data.length === 1 ? "Record" : "Records";
  useEffect(() => {
    setPages(paginate(data.length, pageIndex + 1, 10, 5).pages);
  }, [pageIndex]);
  return (
    <>
      <div className="table-details">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagenation">
        <span className="noof-records">
          {data.length} {recordtext}
        </span>
        <span className="pagenation-numbs">
          <button
            onClick={() => {
              previousPage();
              scrollToTop();
            }}
            disabled={!canPreviousPage}
            className={!canPreviousPage ? "disabled" : ""}
          >
            Previous
          </button>
          {pages.map((option, i) => {
            return (
              <button
                key={i}
                className={
                  pageIndex === option - 1
                    ? "bg-dark text-light"
                    : "bg-light text-dark"
                }
                onClick={() => {
                  gotoPage(option - 1);
                  scrollToTop();
                }}
              >
                {option}
              </button>
            );
          })}
          <button
            onClick={() => {
              nextPage();
              scrollToTop();
            }}
            disabled={!canNextPage}
            className={!canNextPage ? "disabled" : ""}
          >
            Next
          </button>
        </span>
      </div>
    </>
  );
};
