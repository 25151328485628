import React, { useState } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import {
  editOrDelete,
  datevalue,
  editcondtionPreference,
  propcondition,
  HelpSection,
  CategorySec,
  DateSec,
  CommentSec,
  FinalSelection,
  levelcommonprops,
  jsondataForPreference,
  ButtonSec,
  onlyDeleteconditon,
  onlyAddconditon,
  apicall,
  onlyEditconditon,
  deleteOrView,
  DescriptionSec,
  onlyView,
  CSTNote,
  IsExpandable,
  ExpandableId,
  checkexpandle,
} from "../../CommonBlocks/js/CommonBlock";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";
import {
  isValidCategoryName,
  checkFinalLevel,
} from "../../AddPermissionLevels/js/AddPermissionLevels";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMsal } from "@azure/msal-react";
toast.configure();

// Function for Submit for Approval
const handlePrefrenceSubmit = (
  e,
  requestData,
  getPars,
  level,
  parentCategoryName
) => {
  e.preventDefault();
  const { startDate, endDate, categoryname } = requestData;
  if (endDate < startDate) {
    toast.error("End date can't be before start date");
  } else if (
    isValidCategoryName(level, "add", parentCategoryName, categoryname)
  ) {
    toast.error("Category name can't be same as parent category");
  } else {
    getPars("add", "submit");
  }
};

// Modify Submit
const handlePreferenceEditSubmit = (
  e,
  requestData,
  getPars,
  level,
  parentCategoryName
) => {
  e.preventDefault();
  const { startDate, endDate, categoryname } = requestData;
  if (endDate < startDate) {
    toast.error("End date can't be before start date");
  } else if (
    isValidCategoryName(level, "edit", parentCategoryName, categoryname)
  ) {
    toast.error("Category name can't be same as parent category");
  } else {
    getPars("edit", "update");
  }
};

const checkSubcategory = (subcat, hassublevel) => {
  return !!subcat || !!hassublevel;
};

const checkPage = (optType, level) => {
  if (optType === "Edit") {
    return 16;
  } else if (optType === "View") {
    return 21;
  } else if (level === 2) {
    return 14;
  } else {
    return 13;
  }
};

// Main Add new Preference function
function AddPreferenceLevels(props) {
  const subCatExists = checkSubcategory(
    props.category.subCategory,
    props.category.hasSubLevel
  );
  const { instance, accounts } = useMsal();
  const [startPrefDateModified, setStartPrefDateModifed] = useState(false);
  const [endPrefDateModified, setEndPrefDateModifed] = useState(false);
  const id = props.category.id;
  const userData = {
    ...propcondition(props),
    ...levelcommonprops(props),
    id,
    isPreference: true,
    isFinalLevel: false,
  };

  const [requestData, setRequestData] = useState(userData);
  const handlePreferenceChange = (e) => {
    setRequestData({
      ...requestData,
      [e.target.name]: e.target.value,
    });
    e.target.name === "startDate" && setStartPrefDateModifed(true);
    e.target.name === "endDate" && setEndPrefDateModifed(true);
  };
  const onChecked = (e) => {
    setRequestData({
      ...requestData,
      [e.target.id]: e.currentTarget.checked,
    });
  };

  // Save for later
  const onSaveClick = (e) => {
    e.preventDefault();
    getPars("add", "save");
  };

  const getPars = async (func, action) => {
    const token = await tokenAndRolesCapture(instance, accounts);
    if(token != undefined){
    const username = token.idTokenClaims.name;
    const comments = props.category.comments;
    const leveltype = "preference";
    const finaldata = jsondataForPreference({
      ...requestData,
      comments,
      func,
      action,
      username,
      startPrefDateModified,
      endPrefDateModified,
    });
    if (action === "save" && finaldata.adminMetaData.subCategoryName === "") {
      toast.error("Please enter category name");
    } else {
      apicall({
        finaldata,
        func,
        onClose: props.onClose,
        notify: props.notify,
        brand: props.brand,
        action,
        leveltype,
        token,
      });
    }
   }
  };

  return (
    <Modal
      className="modalpopup modal-preference"
      show={props.show}
      onHide={() => props.onClose()}
    >
      <Modal.Header closeButton>
        <p>
          {onlyAddconditon(props.optionType)
            ? "Add New Preference"
            : editcondtionPreference(props.optionType)}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Form
          id="form1"
          onSubmit={
            onlyAddconditon(props.optionType)
              ? (e) =>
                  handlePrefrenceSubmit(
                    e,
                    requestData,
                    getPars,
                    props.level,
                    props.category.subCategoryName ||
                      props.category.categoryName
                  )
              : (e) =>
                  handlePreferenceEditSubmit(
                    e,
                    requestData,
                    getPars,
                    props.level,
                    props.category.parentCategoryName
                  )
          }
        >
          <Row>
            <Col md={12}>
              <p>Level {props.level}</p>
            </Col>
            <HelpSection pageno={checkPage(props.optionType, props.level)} />
          </Row>
          <CategorySec
            category={requestData.categoryname}
            onChange={(e) => handlePreferenceChange(e)}
            status={props.category.status}
            optType={props.optionType}
          />
          <DescriptionSec
            description={requestData.description}
            onChange={(e) => handlePreferenceChange(e)}
            onlyDelete={deleteOrView(props.optionType)}
          />
          <CSTNote />
          <DateSec
            startDate={requestData.startDate}
            endDate={requestData.endDate}
            type={editOrDelete(props.optionType) ? datevalue : "text"}
            onChange={(e) => handlePreferenceChange(e)}
            onlyDelete={onlyDeleteconditon(props.optionType)}
            onlyView={onlyView(props.optionType)}
            onlyAddconditon={onlyAddconditon(props.optionType)}
          />
          {checkFinalLevel(
            subCatExists,
            props.level,
            props.optionType,
            props.category.isFinalLevel
          ) && (
            <FinalSelection
              onChange={(e) => onChecked(e)}
              onChecked={props.category.isFinalLevel || props.level === 5}
              onlyDelete={deleteOrView(props.optionType) || props.level === 5}
            />
          )}
          {checkexpandle(
            props.level,
            props.optionType,
            requestData.isExpandable
          ) && (
            <IsExpandable
              id={ExpandableId}
              onChecked={requestData.isExpandable}
              onlyDelete={deleteOrView(props.optionType)}
              onChange={(e) => onChecked(e)}
            />
          )}
          <CommentSec
            commentText={requestData.commentText}
            onChange={(e) => handlePreferenceChange(e)}
            editOrDelete={
              onlyDeleteconditon(props.optionType) ||
              onlyEditconditon(props.optionType)
            }
            onlyDelete={deleteOrView(props.optionType)}
            onlyView={onlyView(props.optionType)}
            approve={true}
          />
          <ButtonSec {...props} onSaveClick={onSaveClick} />
        </Form>
      </Modal.Body>
    </Modal>
  );
}
export default AddPreferenceLevels;
