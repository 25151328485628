import React, { useRef } from "react";
import {
  Col,
  Row,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  Container,
  Button,
} from "react-bootstrap";

import Moment from "moment";
import MomentTimeZone from "moment-timezone";
import { FaRegQuestionCircle } from "react-icons/fa";
import CommunicationChannel from "../../CommunicationChannel/js/CommunicationChannel";
import { AxiosPost, AxiosPut } from "../../AxiosMethods/ApiCalls";
import AdminPortalReferenceGuide from "../../../static/ECPC-Reference-Guide.pdf";
export const datevalue = "datetime-local";
export const dateFormatString = "YYYY-MM-DD[T]HH:mm:00";
export const ExpandableId = "isExpandable";
export const editOrDelete = (optionType) => {
  return optionType
    ? optionType === "Edit" || optionType === "Delete" || optionType === "View"
    : false;
};

export const deleteOrView = (optionType) => {
  return onlyDeleteconditon(optionType) || onlyView(optionType);
};

export const editOrView = (optionType) => {
  return onlyEditconditon(optionType) || onlyView(optionType);
};

export function dateFormat(date) {
  return Moment(date).format("MM/DD/YYYY hh:mm A");
}

// Convert all the entered Date to CST format America/Chicago
export function cstDateFormat(date) {
  const cstdate = MomentTimeZone(date).tz("America/Chicago");
  return Moment(cstdate).format("MM/DD/YYYY hh:mm:00 A");
}

export function editDateFormat(date) {
  return Moment(date).format("MM/DD/YYYY hh:mm:00 A");
}

export const onlyAddconditon = (optionType) => {
  return optionType === "Add";
};

export const onlyEditconditon = (optionType) => {
  return optionType === "Edit";
};

export const onlyDeleteconditon = (optionType) => {
  return optionType === "Delete";
};

export const onlyView = (optionType) => {
  return optionType === "View";
};

// Funtion to replace heading in View Modify and Delete Pop up for Permission
export const editcondtion = (optionType) => {
  if (onlyEditconditon(optionType)) {
    return "Modify Permission";
  } else if (onlyView(optionType)) {
    return "View Permission";
  } else {
    return "Delete Permission";
  }
};
// check expandle view
export function checkexpandle(level, optype, isexndble) {
  if (onlyView(optype) && isexndble) {
    return true;
  } else {
    return level >= 2 && level <= 4 && !onlyView(optype);
  }
}
// Funtion to replace heading in View Modify and Delete Pop up for Preference
export const editcondtionPreference = (optionType) => {
  if (onlyEditconditon(optionType)) {
    return "Modify Preference";
  } else if (onlyView(optionType)) {
    return "View Preference";
  } else {
    return "Delete Preference";
  }
};

export const formatParentID = (id) => {
  return id.trim().replace(/ /g, "_").toUpperCase();
};

// Fucntion to loop and post the comments
export const forEachComments = (comments) => {
  let eachComment = "";
  comments.map((d) => (eachComment += iterateComments(d)));
  return eachComment;
};

// Iterate and get all comments
export const iterateComments = (comment) => {
  return `${comment.user} : ${comment.time} \nComments : ${
    comment.comment ? comment.comment : ""
  } \n \n`;
};
function addexpandle(optype, category) {
  return onlyAddconditon(optype) ? {} : { ...propForExandable({}, category) };
}
// Common Props josn for all levels
export const propcondition = (category) => {
  const rank = category.category.subCategory
    ? category.category.subCategory.map((d) => d.rank)
    : [0];
  return {
    level: category.level,
    rank: onlyAddconditon(category.optionType)
      ? Math.max(...rank) + 1
      : category.category.rank,
    brand: category.category.brand,
    createdBy: category.category.createdBy,
    createdDate: cstDateFormat(),
    startDate:
      editOrDelete(category.optionType) && category.category.startDate
        ? Moment(category.category.startDate).format(dateFormatString)
        : "",
    endDate:
      editOrDelete(category.optionType) && category.category.endDate
        ? Moment(category.category.endDate).format(dateFormatString)
        : "",
    commentText:
      editOrDelete(category.optionType) && category.category.comments
        ? forEachComments(category.category.comments)
        : "",
    editCommentText:
      editOrDelete(category.optionType) && category.category.editCommentText,
    status: category.category.status,
    description:
      editOrDelete(category.optionType) && category.category.description
        ? category.category.description
        : "",
    ...addexpandle(category.optionType, category.category),
  };
};

// Props specific to level that include in MOC and enable email
export const level1props = (props) => {
  const modeofcommunications =
    typeof props.category.modeOfCommunication !== "object"
      ? props.category.modeOfCommunication &&
        JSON.parse(props.category.modeOfCommunication)
      : props.category.modeOfCommunication;
  const categoryName =
    props.level === 1
      ? props.category.categoryName
      : props.category.subCategoryName;
  return {
    categoryname: editOrDelete(props.optionType) ? categoryName : "",
    enableAlternateEmailId: props.category.enableAlternateEmailId,
    email:
      editOrDelete(props.optionType) && props.category.modeOfCommunication
        ? modeofcommunications.email
        : false,
    mail:
      editOrDelete(props.optionType) && props.category.modeOfCommunication
        ? modeofcommunications.mail
        : false,
    phone:
      editOrDelete(props.optionType) && props.category.modeOfCommunication
        ? modeofcommunications.phone
        : false,
    text:
      editOrDelete(props.optionType) && props.category.modeOfCommunication
        ? modeofcommunications.text
        : false,
    default:
      editOrDelete(props.optionType) && props.category.modeOfCommunication
        ? modeofcommunications.default
        : [],
  };
};

export const levelcommonprops = (props) => {
  return {
    categoryname: editOrDelete(props.optionType)
      ? props.category.subCategoryName
      : "",
    parentId: props.category.id,
  };
};

export const levelDashboardCommonprops = (props) => {
  return {
    categoryname: editOrDelete(props.optionType)
      ? props.category.categoryName
      : "",
    parentId: props.category.id,
  };
};

// Showing commnets on UI in proper format
export const getComments = (categoryData) => {
  const comments = categoryData.comments ? [...categoryData.comments] : [];
  return categoryData.func === "add"
    ? [
        {
          time: cstDateFormat(),
          user: categoryData.username ? categoryData.username.replace("(TMNA)", "").trim() : "",
          comment: categoryData.commentText ? categoryData.commentText : "",
        },
      ]
    : [
        ...comments,
        {
          time: cstDateFormat(),
          user: categoryData.username ? categoryData.username.replace("(TMNA)", "").trim() : "",
          comment: categoryData.editCommentText
            ? categoryData.editCommentText
            : "",
        },
      ];
};

// Check if date is selected or no and if the action type is save
export const dateCheck = (action, date) => {
  return (action === "save" && date === "") || !date;
};

// Check if its edit or delete function
const editOrDeleteFunc = (func) => {
  return func === "edit" || func === "delete";
};

const editOrViewFunc = (func, ismodified, date) => {
  if (func === "submit" || func === "save") {
    return cstDateFormat(date).toString();
  } else if (func === "edit" || func === "view" || !ismodified) {
    return editDateFormat(date);
  } else {
    return cstDateFormat(date).toString();
  }
};

const checklevel = (level) => {
  return level === 2 || level === 3;
};
const checkdesc = (value) => {
  return value ? value : "";
};
const checkdefault = (value) => {
  return value ? value : [];
};

//for isexandable
function propForExandable(resData, categoryData) {
  let data = { ...resData };
  if (categoryData.level >= 2 && categoryData.level <= 4) {
    data = {
      ...data,
      isExpandable: categoryData.isExpandable,
    };
  }
  return data;
}
// Final json that is posted to API
// Enable Email to be read from props to enable functionality
//enableAlternateEmailId: categoryData.enableAlternateEmailId instead of true directly
export const jsondata = (categoryData) => {
  let resData = {
    level: categoryData.level,
    rank: categoryData.rank ? categoryData.rank : 1,
    createdDate: cstDateFormat(),
    comments: getComments(categoryData),
    description: checkdesc(categoryData.description),
  };

  if (!dateCheck(categoryData.action, categoryData.startDate)) {
    resData = {
      ...resData,
      startDate: editOrViewFunc(
        categoryData.action,
        categoryData.startDateModified,
        categoryData.startDate
      ),
    };
  }
  if (!dateCheck(categoryData.action, categoryData.endDate)) {
    resData = {
      ...resData,
      endDate: editOrViewFunc(
        categoryData.action,
        categoryData.endDateModified,
        categoryData.endDate
      ),
    };
  }
  if (categoryData.level === 1) {
    resData = {
      ...resData,
      brand: categoryData.brand,
      categoryName: categoryData.categoryname,
      enableAlternateEmailId: true,
      modeOfCommunication: {
        email: categoryData.email,
        mail: categoryData.mail,
        phone: categoryData.phone,
        text: categoryData.text,
        default: categoryData.default,
      },
    };
    if (editOrDeleteFunc(categoryData.func)) {
      resData = {
        ...resData,
        id: categoryData.id,
        createdBy: categoryData.createdBy,
      };
    }
  } else {
    resData = {
      ...resData,
      subCategoryName: categoryData.categoryname,
    };
    if (categoryData.func === "add") {
      resData = {
        ...resData,
        parentId: categoryData.id,
      };
      if (categoryData.parentId) {
        resData = {
          ...resData,
          parentId: categoryData.parentId,
        };
      }
    } else {
      resData = {
        ...resData,
        id: categoryData.id,
        createdBy: categoryData.createdBy,
      };
    }
  }
  if (checklevel(categoryData.level)) {
    resData = {
      ...resData,
      modeOfCommunication: {
        email: !!categoryData.email,
        mail: !!categoryData.mail,
        phone: !!categoryData.phone,
        text: !!categoryData.text,
        default: checkdefault(categoryData.default),
      },
    };
  }
  resData = propForExandable(resData, categoryData);
  return {
    adminMetaData: {
      ...resData,
    },
  };
};

// Final Preference json that is posted to API
export const jsondataForPreference = (categoryData) => {
  let resData = {
    level: categoryData.level,
    rank: categoryData.rank ? categoryData.rank : 1,
    createdDate: cstDateFormat(),
    isPreference: true,
    parentId: categoryData.parentId,
    comments: getComments(categoryData),
    description: categoryData.description,
    isFinalLevel: categoryData.level === 5 ? true : categoryData.isFinalLevel,
  };
  if (categoryData.level === 1) {
    resData = {
      ...resData,
      categoryName: categoryData.categoryname,
    };
  } else {
    resData = {
      ...resData,
      subCategoryName: categoryData.categoryname,
    };
  }
  if (!dateCheck(categoryData.action, categoryData.startDate)) {
    resData = {
      ...resData,
      startDate: editOrViewFunc(
        categoryData.action,
        categoryData.startPrefDateModified,
        categoryData.startDate
      ),
    };
  }
  if (!dateCheck(categoryData.action, categoryData.endDate)) {
    resData = {
      ...resData,
      endDate: editOrViewFunc(
        categoryData.action,
        categoryData.endPrefDateModified,
        categoryData.endDate
      ),
    };
  }
  if (categoryData.func === "edit") {
    resData = {
      ...resData,
      createdBy: categoryData.createdBy,
      id: categoryData.parentId,
    };
  }
  if (categoryData.func === "add") {
    resData = {
      ...resData,
      parentId: categoryData.id,
    };
    if (categoryData.parentId) {
      resData = {
        ...resData,
        parentId: categoryData.parentId,
      };
    }
  }
  resData = propForExandable(resData, categoryData);
  return {
    adminMetaData: {
      ...resData,
    },
  };
};

// Help Section to Guide the user
export const HelpSection = (props) => {
  const openPdf = () => {
    window.open(`${AdminPortalReferenceGuide}#page=${props.pageno}`);
  };
  return (
    <Col md={6}>
      <div className="help-sec" onClick={openPdf}>
        <span className="help-icon">
          <FaRegQuestionCircle varient="red" />
        </span>
        <p>Help</p>
      </div>
    </Col>
  );
};

// Common Category Section
export const CategorySec = (props) => {
  const checkReadOnly = () => {
    let readvalue = true;
    if (
      onlyAddconditon(props.optType) ||
      (onlyEditconditon(props.optType) && props.status === "Draft")
    ) {
      readvalue = false;
    }
    return readvalue;
  };
  return (
    <Row className="category-sec">
      <Col md={10}>
        <FormControl
          type="text"
          name="categoryname"
          placeholder="Category Name*"
          required={true}
          value={props.category}
          onChange={props.onChange}
          onBlur={props.onChange}
          readOnly={checkReadOnly()}
          pattern="[a-zA-Z0-9-& ]+"
          title="Please do not use special characters in category name!"
        />
      </Col>
    </Row>
  );
};

// Enable Email Section
// Hidden with CSS now, can be enabled later if needed
export const EnableEmailSec = (props) => {
  return (
    <Row className="enable-email">
      <Col md={5}>Enable alternate email address</Col>
      <Col md={5}>
        <ToggleButtonGroup
          className="email-options"
          name="altEmail"
          type="radio"
          value={props.altEmail ? "YES" : "NO"}
          onChange={props.onChange}
          readOnly={props.onlyDelete}
        >
          <ToggleButton
            variant={props.altEmail ? "dark" : "light"}
            value="YES"
            className="shadow-none"
            name="altEmail"
            id="altEmailYes"
          >
            Yes
          </ToggleButton>

          <ToggleButton
            variant={props.altEmail ? "light" : "dark"}
            value="NO"
            className="shadow-none"
            name="altEmail"
            id="altEmailNo"
          >
            No
          </ToggleButton>
        </ToggleButtonGroup>
      </Col>
    </Row>
  );
};

// Common Comment Section that can be used in other components
export const CommentSec = (props) => {
  return (
    <>
      <Row>
        <Col>
          <textarea
            placeholder="Comments*"
            name="commentText"
            value={props.commentText}
            readOnly={props.editOrDelete || props.onlyDelete}
            onChange={props.onChange}
            required={true}
            className="form-control"
            onBlur={props.onChange}
          ></textarea>
        </Col>
      </Row>
      {props.editOrDelete && props.approve && !props.onlyView && (
        <Row>
          <Col>
            <textarea
              data-testid="editcomments"
              placeholder="Comments*"
              name="editCommentText"
              value={props.editCommentText}
              onChange={props.onChange}
              required={true}
              className="form-control"
              onBlur={props.onChange}
            ></textarea>
          </Col>
        </Row>
      )}
    </>
  );
};

// Common Date Section that can be used in other components
export const DateSec = (props) => {
  const mindateValue = useRef(props.startDate);
  const mindateFunc = () => {
    if (
      props.onlyAddconditon ||
      mindateValue.current > Moment().format(dateFormatString) ||
      mindateValue.current === ""
    ) {
      return Moment().format(dateFormatString);
    } else {
      return Moment(mindateValue.current).format(dateFormatString);
    }
  };
  return (
    <Row className="date-wrap">
      <Col md={5}>
        <FormControl
          type={props.type}
          name="startDate"
          id="startDate"
          placeholder="Start Date and Time*"
          onFocus={(e) => (e.target.type = datevalue)}
          required={true}
          onChange={props.onChange}
          value={props.startDate}
          readOnly={props.onlyDelete || props.onlyView}
          min={mindateFunc()}
        />
      </Col>
      <Col md={5}>
        <FormControl
          type={props.type}
          name="endDate"
          id="endDate"
          placeholder="End Date and Time*"
          required={true}
          onFocus={(e) => (e.target.type = datevalue)}
          value={props.endDate}
          onChange={props.onChange}
          readOnly={props.onlyView}
          min={Moment().format(dateFormatString)}
        />
      </Col>
    </Row>
  );
};

// Common Moode of communicaiton Section that can be used in other components
export const DefaultCommunications = ({
  name,
  id,
  onDefaultCommChecked,
  onlyDelete,
  defaultChecked,
}) => {
  return (
    <>
      <input
        name={name}
        type="checkbox"
        id={id}
        readOnly={onlyDelete}
        onChange={onDefaultCommChecked}
        checked={defaultChecked}
      />
      <label htmlFor={id}></label>
    </>
  );
};

export const DefaultCommunicationModes = (props) => {
  const checkDefaultComm = (paramvalue) => {
    return props.default.some((d) => d === paramvalue);
  };

  return (
    <div className="select-default-modes" readOnly={props.onlyDelete}>
      <Row className="comm-mode">
        <Col md={6}>
          <p style={{ whiteSpace: "nowrap", fontSize: "14px" }}>
            Mode of communication allowed{props.level === 1 ? <sup>*</sup> : ""}
          </p>
        </Col>
        <Col md={6} className="mode-comm-group">
          {[
            {
              name: "email",
              id: `email${props.level}`,
              value: "Email",
              checkedimgSrc: "mail-white.svg",
              imgSrc: "mail-dark.svg",
            },
            {
              name: "mail",
              id: `mail${props.level}`,
              value: "Mail",
              checkedimgSrc: "post-white.svg",
              imgSrc: "post-dark.svg",
            },
            {
              name: "phone",
              id: `phone${props.level}`,
              value: "Phone",
              checkedimgSrc: "Icon feather-phone-call.png",
              imgSrc: "Icon feather-phone-call-unchecked.png",
            },
            {
              name: "text",
              id: `text${props.level}`,
              value: "Text",
              checkedimgSrc: "msg-icon-checked.png",
              imgSrc: "Icon material-textsms.png",
            },
          ].map((d, i) => {
            return (
              <CommunicationChannel
                key={i}
                id={d.id}
                Checked={props[d.name]}
                value={d.value}
                onChecked={() =>
                  props.onChecked(d.name, props.level, !props[d.name])
                }
                checkedimgSrc={d.checkedimgSrc}
                imgSrc={d.imgSrc}
                desc={d.value}
              />
            );
          })}
        </Col>
      </Row>
      <Row className="default-mode">
        <Col md={6}>
          <p style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
            Select default for new or guest customer
          </p>
        </Col>
        <Col md={6}>
          {[
            { name: "email", id: `emailselected${props.level}` },
            { name: "mail", id: `mailselected${props.level}` },
            { name: "phone", id: `phoneselected${props.level}` },
            { name: "text", id: `textselected${props.level}` },
          ].map((d, i) => {
            return (
              <DefaultCommunications
                key={i}
                name={d.name}
                id={d.id}
                onlyDelete={props.onlyDelete}
                onDefaultCommChecked={props.onDefaultCommChecked}
                defaultChecked={checkDefaultComm(d.name)}
              />
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

// Common Is final level checkbox that can be used in other components
// If is final level is selected that will be last level
export const FinalSelection = (props) => {
  return (
    <Row className="selection">
      <Col md={12}>
        <FormControl
          name="finalLevel"
          type="checkbox"
          id="isFinalLevel"
          defaultChecked={props.onChecked}
          onChange={props.onChange}
          readOnly={props.onlyDelete}
          disabled={props.onlyDelete}
        />
        <label htmlFor="isFinalLevel">Is this final level</label>
      </Col>
    </Row>
  );
};
export const IsExpandable = (props) => {
  return (
    <Row className="selection">
      <Col md={12}>
        <FormControl
          name="isExpandable"
          type="checkbox"
          id={props.id}
          defaultChecked={props.onChecked}
          onChange={props.onChange}
          readOnly={props.onlyDelete}
          disabled={props.onlyDelete}
        />
        <label htmlFor={props.id}>Is expandable</label>
      </Col>
    </Row>
  );
};

// Common Description Section that can be used in other components
export const DescriptionSec = (props) => {
  return (
    <Row>
      <Col>
        <textarea
          placeholder="Add the Description here.."
          name="description"
          defaultValue={props.description}
          readOnly={props.onlyDelete}
          onChange={props.onChange}
          className="form-control"
          onBlur={props.onChange}
        ></textarea>
      </Col>
    </Row>
  );
};

// Common Submit button Section that can be used in other components
export const ButtonSec = (props) => {
  return (
    <>
      {props.optionType !== "View" && (
        <Container fluid className="button-options">
          <Button variant="primary" size="sm" onClick={() => props.onClose()}>
            Cancel
          </Button>

          {!editOrDelete(props.optionType) && (
            <Button variant="primary" size="sm" onClick={props.onSaveClick}>
              Save for Later
            </Button>
          )}
          <Button type="submit" variant="secondary" size="sm">
            {props.optionType === "Delete"
              ? "Submit Delete for Approval"
              : "Submit for Approval"}
          </Button>
        </Container>
      )}
    </>
  );
};

// Common Button Section for Approve / Reject Screen
export const ActionButtonSec = (props) => {
  return (
    <Container fluid className="button-options">
      <Button
        variant="primary"
        size="sm"
        onClick={() => props.onAction("reject")}
      >
        Reject
      </Button>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => props.onAction("approve")}
      >
        Approve
      </Button>
    </Container>
  );
};

// API call function
export const apicall = async ({
  finaldata,
  func,
  onClose,
  notify,
  brand,
  action,
  leveltype,
  token,
}) => {
  const type = finaldata.adminMetaData.level === 1 ? leveltype : "subCategory";
  const postData = { finaldata, type, brand, action };
  let resText = "";
  const result =
    func === "add"
      ? await AxiosPost(postData, token)
      : await AxiosPut(postData, token);
  if (result.code === "200") {
    onClose();
    resText = result.messages[0].description;
    notify(resText, "success");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } else {
    result.messages.map((i) => {
      resText += `${i.description}\n`;
    });
    notify(resText, "error");
  }
};

// Scroll to top function
export const scrollToTop = () => {
  window.scroll({
    top: 2,
    left: 0,
    behavior: "smooth",
  });
};

// CST Date Note
export const CSTNote = () => {
  return (
    <Row className="cst-note">
      <Col>
        <p>Note: The below date and time will be recorded in CST time zone!</p>
      </Col>
    </Row>
  );
};
