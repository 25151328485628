import React, { useState, useEffect } from "react";
import "../scss/DashboardComponent.scss";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import { dateFormat } from "../../CommonBlocks/js/CommonBlock";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";
import { AxiosGet, AxiosPostMetadata } from "../../AxiosMethods/ApiCalls";
import AddPermissionLevels from "../../AddPermissionLevels/js/AddPermissionLevels";
import AddPreferenceLevels from "../../AddPreferenceLevels/js/AddPreferenceLevels";
import ApproveRejectScreen from "../../ApproveRejectScreen/js/ApproveRejectScreen";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";

const navalue = "N/A";
const pageSize = 4;
const maxPages = 5;
export function paginate(totalItems, currentPage, pgSize, maxPgs) {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pgSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage, endPage;
  if (totalPages <= maxPgs) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPgs / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPgs / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPgs;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPgs + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pgSize;
  const endIndex = Math.min(startIndex + pgSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i
  );

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pgSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
}
const checkUndefined = (value) => {
  return value ? value : navalue;
};

const checkDateUndefined = (datevalue) => {
  return datevalue ? dateFormat(datevalue) : navalue;
};
function createGroups(arr, numGroups) {
  const perGroup = Math.ceil(arr.length / numGroups);
  const newgroup = Array.from(Array(perGroup).keys());
  return newgroup.map((_, i) => arr.slice(i * numGroups, (i + 1) * numGroups));
}
const GettabTitle = ({ type }) => {
  if (type === "actionItem") {
    return <p>Action Items</p>;
  } else if (type === "recentActivity") {
    return <p>All My Portal Activity</p>;
  } else {
    return <p>All Portal Activity</p>;
  }
};
const GetlActionColHeads = ({ type }) => {
  if (type === "actionItem") {
    return (
      <>
        <th>Created By</th>
        <th>Created Date</th>
        <th>Last Modified</th>
        <th className="review">Action</th>
      </>
    );
  } else if (type === "recentActivity") {
    return (
      <>
        <th>Created By</th>
        <th>Created Date</th>
        <th>Last Modified</th>
        <th>Status</th>
        <th className="edit"></th>
      </>
    );
  } else {
    return (
      <>
        <th>Created Date</th>
        <th>Last Modified By</th>
        <th>Last Modified</th>
        <th>Status</th>
        <th className="view">View</th>
      </>
    );
  }
};

const checkeditbale = (status) => {
  return status === "Pending Approval" || status === "Inactive";
};
const GetActionColBody = (props) => {
  const isnoteditable = checkeditbale(props.recentlist.status);
  if (props.type === "actionItem") {
    return (
      <>
        <td>{checkUndefined(props.recentlist.createdBy)}</td>
        <td>{checkDateUndefined(props.recentlist.createdDate)}</td>
        <td>{checkDateUndefined(props.recentlist.modifiedDate)}</td>
        <td
          className="review"
          data-testid="review-click"
          onClick={(e) => {
            props.setshowTrue();
            props.modifyData(props.recentlist, props.brand, props.type);
          }}
        >
          Review to Approve/Reject
        </td>
      </>
    );
  } else if (props.type === "recentActivity") {
    return (
      <>
        <td>{checkUndefined(props.recentlist.createdBy)}</td>
        <td>{checkDateUndefined(props.recentlist.createdDate)}</td>
        <td>{checkDateUndefined(props.recentlist.modifiedDate)}</td>
        <td>{checkUndefined(props.recentlist.status)}</td>
        <td
          data-testid="view-click"
          className={isnoteditable ? "view" : "edit"}
          onClick={() => {
            props.setshowTrue();
            props.modifyData(props.recentlist, props.brand, props.type);
          }}
        >
          {isnoteditable ? <FaRegEye /> : <FaRegEdit />}
        </td>
      </>
    );
  } else {
    return (
      <>
        <td>{checkDateUndefined(props.recentlist.createdDate)}</td>
        <td>{checkUndefined(props.recentlist.modifiedBy)}</td>
        <td>{checkDateUndefined(props.recentlist.modifiedDate)}</td>
        <td>{checkUndefined(props.recentlist.status)}</td>
        <td
          className="view"
          data-testid="view-click"
          onClick={() => {
            props.setshowTrue();
            props.modifyData(props.recentlist, props.brand, props.type);
          }}
        >
          <FaRegEye />
        </td>
      </>
    );
  }
};
const setmodalshow = (isModalShow, resposnsedata) => {
  return isModalShow && resposnsedata && resposnsedata.level;
};
const sortFechedDate = (fetcheData) => {
  return fetcheData
    ? fetcheData.sort(
        (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
      )
    : [];
};

// Pagination
const PagenationSec = ({
  fetcheDataLength,
  page,
  pages,
  tableLength,
  gotoPage,
}) => {
  const recordTxt = fetcheDataLength === 1 ? "Record" : "Records";
  return (
    <div className="pagenation">
      <span className="noof-records">
        {fetcheDataLength} {recordTxt}
      </span>
      <span className="pagenation-numbs">
        <button
          data-testid="page-prev"
          onClick={() => page > 1 && gotoPage(page - 1)}
          disabled={page === 1}
          className={page === 1 ? "disabled" : ""}
        >
          Previous
        </button>
        {pages.map((option, i) => {
          return (
            <button
              data-testid={`page-${option}`}
              key={i}
              className={
                page === option ? "bg-dark text-light" : "bg-light text-dark"
              }
              onClick={() => gotoPage(option)}
            >
              {option}
            </button>
          );
        })}
        <button
          data-testid="page-next"
          onClick={() => page < tableLength && gotoPage(page + 1)}
          disabled={page === tableLength}
          className={page === tableLength ? "disabled" : ""}
        >
          Next
        </button>
      </span>
    </div>
  );
};

// Common component to display all three tables based on conditions
export const ActivityTable = (props) => {
  const modalshow = setmodalshow(props.isModalShow, props.resposnsedata);
  const fetcheData = sortFechedDate(props.fetcheData);
  const [tabledata, setTableData] = useState([]);
  const [page, gotoPage] = useState(1);
  const [pages, setPages] = useState([]);
  const Permisionprops = {
    show: props.isModalShow,
    onClose: props.onclose,
    category: props.resposnsedata,
    brand: props.brand,
    level: props.resposnsedata.level,
    notify: props.notify,
  };
  const noedit = checkeditbale(props.resposnsedata.status);
  useEffect(() => {
    setTableData(createGroups(fetcheData, 4));
    setPages(
      paginate(fetcheData && fetcheData.length, page, pageSize, maxPages).pages
    );
  }, [props.fetcheData]);
  useEffect(() => {
    setPages(
      paginate(fetcheData && fetcheData.length, page, pageSize, maxPages).pages
    );
  }, [page]);
  useEffect(() => {
    gotoPage(1);
  }, [props.brand]);
  return (
    <>
      <div className="recent-activity">
        <GettabTitle type={props.type} />
        <table className="table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Level 2</th>
              <th>Level 3</th>
              <th>Level 4</th>
              <th>Level 5</th>
              <GetlActionColHeads type={props.type} />
            </tr>
          </thead>
          <tbody>
            {tabledata.length > 0 &&
              tabledata[page - 1]
                .sort(
                  (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
                )
                .map((recentlist, index) => {
                  return (
                    <tr key={index}>
                      <td>{checkUndefined(recentlist.levels[0])}</td>
                      <td>{checkUndefined(recentlist.levels[1])}</td>
                      <td>{checkUndefined(recentlist.levels[2])}</td>
                      <td>{checkUndefined(recentlist.levels[3])}</td>
                      <td>{checkUndefined(recentlist.levels[4])}</td>
                      <GetActionColBody {...props} recentlist={recentlist} />
                    </tr>
                  );
                })}
          </tbody>
        </table>

        {props.isLoading && <span>Loading...</span>}
        {tabledata.length === 0 && !props.isLoading && (
          <p className="text-danger justify-center">{props.errorMessage}</p>
        )}
        {props.type === "recentActivity" &&
          modalshow &&
          !props.resposnsedata.isPreference && (
            <AddPermissionLevels
              optionType={noedit ? "View" : "Edit"}
              {...Permisionprops}
            />
          )}
        {props.type === "recentActivity" &&
          modalshow &&
          props.resposnsedata.isPreference && (
            <AddPreferenceLevels
              {...Permisionprops}
              optionType={noedit ? "View" : "Edit"}
            />
          )}
        {props.type === "actionItem" && modalshow && (
          <ApproveRejectScreen {...Permisionprops} optionType="View" />
        )}
        {props.type === "recentUpdate" &&
          modalshow &&
          !props.resposnsedata.isPreference && (
            <AddPermissionLevels {...Permisionprops} optionType="View" />
          )}
        {props.type === "recentUpdate" &&
          modalshow &&
          props.resposnsedata.isPreference && (
            <AddPreferenceLevels {...Permisionprops} optionType="View" />
          )}
        {tabledata.length > 0 && (
          <PagenationSec
            fetcheDataLength={props.fetcheData && props.fetcheData.length}
            page={page}
            pages={pages}
            tableLength={tabledata.length}
            gotoPage={gotoPage}
          />
        )}
        {props.type !== "actionItem" && (
          <div className="recent-activity-list">
            <p>
              {props.linkText}&nbsp;
              <Link to={`/${props.hrefLink}`}>Click here</Link>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

// Records can be approved only if the parent record is in Live state
const checkinrecentUpdate = (fetcheData, record) => {
  return fetcheData.some((e) => {
    if (e.id === record.id || e.id.includes(record.id)) {
      return false;
    } else {
      return (
        e.levels.at(-1) === record.levels.at(-2) &&
        (e.status === "Draft" ||
          e.status === "Rejected" ||
          e.status === "Approved Pending Go-Live")
      );
    }
  });
};
const checkinActionItems = (fetcheData, record) => {
  return fetcheData.some((e) => {
    if (e.id === record.id || e.id.includes(record.id)) {
      return false;
    } else {
      return e.levels.at(-1) === record.levels.at(-2);
    }
  });
};

// Main Dashboard Component
function DashboardComponent(props) {
  const [fetcheData, setFetchedData] = useState([]);
  const [isNoDataFound, setNoDataFound] = useState(false);
  const [showViewLevel, SetShowViewLevel] = useState(false);
  const [showModifyLevel, SetShowModifyLevel] = useState(false);
  const [showActionLevel, SetShowActionLevel] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("No Data Found");
  const { instance, accounts } = useMsal();
  useEffect(() => {
    getApiCall();
  }, [props.brand, props.type]);

  const getApiCall = async () => {
    setLoading(true);
    setFetchedData([]);
    const token = await tokenAndRolesCapture(instance, accounts);
    const getDataApi = AxiosGet(
      {
        brand: props.brand,
        type: `${props.type}`,
      },
      token
    );
    getDataApi.then(
      (result) => {
        result.data.data
          ? setFetchedData(result.data.data)
          : setNoDataFound(true);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        setErrorMessage(
          error.response.data.message.error.messages[0].description
        );
        setNoDataFound(true);
      }
    );
  };

  const [resposnsedata, setData] = useState([]);
  const modifyData = async (data, brand, type) => {
    let isCantApprove = true;
    if (type === "actionItem") {
      isCantApprove =
        !checkinActionItems(fetcheData.actionItem, data) &&
        !checkinrecentUpdate(fetcheData.recentUpdate, data);
    }
    if (isCantApprove) {
      const finalData = {
        adminMetaData: {
          id: data.id,
          brand: brand,
        },
      };
      const token = await tokenAndRolesCapture(instance, accounts);
      const getMetaData = await AxiosPostMetadata(finalData, token);
      setData(getMetaData.data);
    } else {
      toast.error("Please make sure the parent record has live status");
    }
  };
  const notify = (res, resType) => {
    if (resType === "success") {
      toast.success(res);
      getApiCall();
    } else {
      toast.error(res);
    }
  };
  return (
    <div className="dashboard-component" id="dashboard-page">
      <ToastContainer />
      {fetcheData && (
        <>
          {fetcheData.actionItem && (
            <ActivityTable
              type="actionItem"
              fetcheData={fetcheData.actionItem}
              resposnsedata={resposnsedata}
              brand={props.brand}
              onclose={() => {
                SetShowActionLevel(false);
                setData([]);
              }}
              isModalShow={showActionLevel}
              setshowTrue={() => SetShowActionLevel(true)}
              isNoDataFound={isNoDataFound}
              modifyData={modifyData}
              notify={notify}
              isLoading={isLoading}
              errorMessage={errorMessage}
            />
          )}
          <ActivityTable
            type="recentActivity"
            fetcheData={fetcheData.recentActivity}
            resposnsedata={resposnsedata}
            brand={props.brand}
            onclose={() => {
              SetShowModifyLevel(false);
              setData([]);
            }}
            isModalShow={showModifyLevel}
            setshowTrue={() => SetShowModifyLevel(true)}
            isNoDataFound={isNoDataFound}
            modifyData={modifyData}
            notify={notify}
            linkText={`To See all My Portal activities`}
            hrefLink={`report-audit`}
            isLoading={isLoading}
            errorMessage={errorMessage}
          />
          <ActivityTable
            type="recentUpdate"
            fetcheData={fetcheData.recentUpdate}
            resposnsedata={resposnsedata}
            brand={props.brand}
            onclose={() => {
              SetShowViewLevel(false);
              setData([]);
            }}
            setshowTrue={() => SetShowViewLevel(true)}
            isModalShow={showViewLevel}
            isNoDataFound={isNoDataFound}
            modifyData={modifyData}
            notify={notify}
            linkText={`To See all Portal activities`}
            hrefLink={`report-perm`}
            isLoading={isLoading}
            errorMessage={errorMessage}
          />
        </>
      )}
    </div>
  );
}

export default DashboardComponent;
