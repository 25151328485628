import { loginRequest } from "../../authconfig";
// Token generation for API calls
export async function tokenAndRolesCapture(instance, accounts) {
  return instance
    .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
    .then((response) => {
      return response;
    })
    .catch(async (err) => {
      return instance.acquireTokenRedirect(loginRequest);
    });
}
export const TestModule = {
  tokenAndRolesCapture,
};
