import React, { useEffect } from "react";
import Header from "../../Header/js/Header";
import { Row, Col, Container, Navbar, Nav } from "react-bootstrap";
import "../scss/DashboardAdmin.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LeftNavigation from "../../LeftNavigation/js/LeftNavigation";
import DashboardComponent from "../../DashboardComponent/js/DashboardComponent";
import ManageComponent from "../../ManageComponent/js/ManageComponent";
import ReportsPermissionAndPreference from "../../Reports/js/Reports-PermissionAndPreference";
import ReportsAudit from "../../Reports/js/Reports-Audit";
import { useMsal } from "@azure/msal-react";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";

// Dashboard Page - Landing page with Left Navigation, Dashboard Component and Manage Component
function DashboardAdmin() {
  const [brand, setBrand] = React.useState("");
  const { instance, accounts } = useMsal();
  useEffect(() => {
    getDefaultBrand();
  }, []);
  async function getDefaultBrand() {
    const token = await tokenAndRolesCapture(instance, accounts);
    if(token != undefined){
    const roles = token.idTokenClaims.roles;
    if (roles) {
      roles.map((role) => {
        if (role.includes("LEXUS")) {
        	if(document.getElementById("lexus-brand") != null){
        		document.getElementById("lexus-brand").click();
        		setBrand("Lexus");
        	}
        }else{
        	if(document.getElementById("toyota-brand") != null){
	          document.getElementById("toyota-brand").click();
	          setBrand("Toyota");
        	}
        }
      });
    }
   }
  }
  return (
    <div className="dashboardadmin-wrapper">
      <Header />
      <Container fluid className="dashboardadmin">
        <div className="dashboardadmin-inner">
          <Router>
            <div className="dashboardadmin-navbar">
              <Navbar>
                <Nav className="me-auto" defaultActiveKey="#Toyota">
                  <Nav.Link
                    activeclassname="active"
                    href="#Toyota"
                    onClick={() => setBrand("Toyota")}
                    id="toyota-brand"
                  >
                    Toyota
                  </Nav.Link>

                  <Nav.Link
                    href="#Lexus"
                    id="lexus-brand"
                    onClick={() => setBrand("Lexus")}
                  >
                    Lexus
                  </Nav.Link>
                </Nav>
              </Navbar>
            </div>

            <div className="dashboardadmin-body">
              <Row className="dashboardadmin-inner">
                <Col md={2} className="left-col">
                  <LeftNavigation />
                </Col>

                <Col md={10} className="right-col" id="activity">
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/dashboard" />
                    </Route>
                    <Route exact path={`/dashboard`}>
                      <DashboardComponent brand={brand} type={"dashboard"} />
                    </Route>
                    <Route exact path={`/manage`}>
                      <ManageComponent brand={brand} type={"metaDataList"} />
                    </Route>
                    <Route exact path={`/report-perm`}>
                      <ReportsPermissionAndPreference brand={brand} />
                    </Route>
                    <Route exact path={`/report-audit`}>
                      <ReportsAudit brand={brand} />
                    </Route>
                  </Switch>
                </Col>
              </Row>
            </div>
          </Router>
        </div>
      </Container>
    </div>
  );
}
export default DashboardAdmin;
