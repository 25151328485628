import React, { useState, useEffect } from "react";
import "../scss/Reports-PermissionAndPreference.scss";
import { Paginated } from "./Pagenated";
import { AxiosGet } from "../../AxiosMethods/ApiCalls";
import Moment from "moment";
import statusDetails from "../../CategoriesData/StatusDetails.json";
import { dateFormat, scrollToTop } from "../../CommonBlocks/js/CommonBlock";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";
import ExportDataToExcel from "./ExportDataToExcel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from "@azure/msal-react";
const createdDateString = "Created Date";
const lastModifiedString = "Last Modified";
const createdByString = "Created By";
const naString = "N/A";
statusDetails.sort(function (a, b) {
  var statusA = a.status.toUpperCase();
  var statusB = b.status.toUpperCase();
  if (statusA < statusB) {
    return -1; //nameA comes first
  }
  if (statusA > statusB) {
    return 1;
  }
  return 0;
});
const DateFormat = () => {
  return Moment().format("YYYY-MM-DD");
};
const checkDateFormat = (date) => {
  return Moment(date).format("MM/DD/YYYY");
};

// Function to filter based on date and various status
function Filters(props) {
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const [daterange, setdaterange] = useState([
    new Date(props.fromdate),
    new Date(props.todate),
  ]);
  const [startDate, endDate] = daterange;
  const handleOnChange = (ranges) => {
    const [startdate, enddate] = ranges;
    !enddate ? setBtnDisabled(true) : setBtnDisabled(false);
    setdaterange(ranges);
    props.setFromDate(checkDateFormat(startdate));
    props.setToDate(checkDateFormat(enddate));
  };
  return (
    <div className="report-filters">
      <label>Created Date:</label>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        monthsShown={1}
        maxDate={new Date()}
        onChange={handleOnChange}
      />
      <label>Category:</label>
      <select
        id="Category"
        name="Category"
        defaultValue={props.Category}
        onChange={props.onSelectable}
      >
        <option value="" selected="selected">
          All Categories
        </option>
        {props.categories.map((cat, pos) => {
          return (
            <option key={pos} value={cat}>
              {cat}
            </option>
          );
        })}
      </select>
      <label>Status:</label>
      <select
        id="Status"
        name="Status"
        value={props.Status}
        onChange={props.onSelectable}
      >
        <option value="" selected="selected">
          All Statuses
        </option>
        {props.statusDetails.map((st, pos) => {
          return (
            <option key={pos} value={st.status}>
              {st.status}
            </option>
          );
        })}
      </select>
      <button
        onClick={props.onView}
        className="btn btn-dark btn-sm"
        disabled={isBtnDisabled || props.iserror}
      >
        Generate Report
      </button>
      <ExportDataToExcel data={props.data} columns={props.columns} />
    </div>
  );
}
const gridcolumns = [
  {
    Header: "Category",
    accessor: "Category",
  },
  {
    Header: "Level 2",
    accessor: "Level 2",
  },
  {
    Header: "Level 3",
    accessor: "Level 3",
  },

  {
    Header: "Level 4",
    accessor: "Level 4",
  },
  {
    Header: "Level 5",
    accessor: "Level 5",
  },
  {
    Header: createdByString,
    accessor: createdByString,
  },

  {
    Header: createdDateString,
    accessor: createdDateString,
  },
  {
    Header: lastModifiedString,
    accessor: lastModifiedString,
  },
  {
    Header: "Status",
    accessor: "Status",
  },
];
const forTable = (data, setReportData) => {
  let formattedData = [];
  data.map((item) => {
    const obj = {
      Category: item.levels[0],
      "Level 2": item.levels[1] ? item.levels[1] : naString,
      "Level 3": item.levels[2] ? item.levels[2] : naString,
      "Level 4": item.levels[3] ? item.levels[3] : naString,
      "Level 5": item.levels[4] ? item.levels[4] : naString,
      [createdByString]: item.createdBy,
      [createdDateString]: Moment(item.createdDate).format(
        "MM/DD/YYYY hh:mm A"
      ),
      [lastModifiedString]: dateFormat(item.modifiedDate),
      Status: item.status ? item.status : "",
    };
    formattedData = [...formattedData, obj];
  });
  setReportData(formattedData);
};
const onSelectable = (e, setCategory, setStatus) => {
  const { name, value } = e.target;
  if (name === "Category") {
    setCategory(value);
  } else {
    setStatus(value);
  }
};
const queryPars = (catname, status) => {
  let queryString = "";
  if (catname !== "") {
    queryString += `&categoryName=${catname}`;
  }
  if (status !== "") {
    queryString += `&status=${status}`;
  }
  return queryString;
};

// Main Reports Function
export function ReportsPermissionAndPreference(props) {
  const [Category, setCategory] = useState("");
  const [Status, setStatus] = useState("");
  const [fromdate, setFromDate] = useState(
    Moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD")
  );
  const [todate, setToDate] = useState(DateFormat());
  const [reportData, setReportData] = useState([]);
  const [isNoDataFound, setNoDataFound] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const { instance, accounts } = useMsal();
  const [iserror, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("No Data Found");
  useEffect(() => {
    setReportData([]);
    getCategories("categories");
    scrollToTop();
    setError(false);
  }, [props.brand, props.type]);
  const getApiCall = async (filters) => {
    setReportData([]);
    setLoading(true);
    const token = await tokenAndRolesCapture(instance, accounts);
    AxiosGet(
      {
        brand: props.brand,
        type: `${props.type}&startDate=${checkDateFormat(
          fromdate
        )}&endDate=${checkDateFormat(todate)}${filters}`,
      },
      token
    ).then(
      (res) => {
        setLoading(false);
        res.data.data
          ? forTable(res.data.data, setReportData)
          : setNoDataFound(true);
        setError(false);
      },
      (error) => {
        setLoading(false);
        setErrorMessage(
          error.response.data.message.error.messages[0].description
        );
        setNoDataFound(true);
        setError(true);
      }
    );
  };
  const getCategories = async (pars) => {
    const token = await tokenAndRolesCapture(instance, accounts);
    AxiosGet(
      {
        brand: props.brand,
        type: pars,
      },
      token
    ).then(
      (res) => {
        res.data.data && LoadCategories(res.data.data);
      },
      (error) => {
        setLoading(false);
        setErrorMessage(
          error.response.data.message.error.messages[0].description
        );
        setNoDataFound(true);
      }
    );
  };
  const LoadCategories = (data) => {
    setCategories(data.map((item) => item.categoryName));
  };

  const DataFetch = () => {
    getApiCall(queryPars(Category, Status));
  };
  return (
    <div className="report-permpref">
      <Filters
        onView={(e) => DataFetch()}
        onSelectable={(e) => onSelectable(e, setCategory, setStatus)}
        Category={Category}
        Status={Status}
        fromdate={fromdate}
        todate={todate}
        statusDetails={statusDetails}
        categories={categories}
        data={reportData}
        columns={gridcolumns}
        setFromDate={setFromDate}
        setToDate={setToDate}
        iserror={iserror}
      />
      {reportData.length > 0 && (
        <Paginated columns={gridcolumns} data={reportData} />
      )}
      {(isNoDataFound || reportData.length === 0) && (
        <div className="row justify-content-sm-center text-center">
          {isLoading ? (
            <span className="col-sm-2">Loading...</span>
          ) : (
            <span className="text-danger col-sm-4">{errorMessage}</span>
          )}
        </div>
      )}
    </div>
  );
}
export default function ReportsPermissionPreference(props) {
  return (
    <ReportsPermissionAndPreference
      {...props}
      type={`report&type=permissionOrPreference`}
    />
  );
}
