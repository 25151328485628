import React, { useState } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import {
  editOrDelete,
  datevalue,
  editcondtion,
  propcondition,
  HelpSection,
  CategorySec,
  EnableEmailSec,
  DateSec,
  CommentSec,
  FinalSelection,
  DefaultCommunicationModes,
  level1props,
  levelcommonprops,
  jsondata,
  ButtonSec,
  onlyAddconditon,
  onlyDeleteconditon,
  apicall,
  onlyView,
  deleteOrView,
  DescriptionSec,
  CSTNote,
  IsExpandable,
  ExpandableId,
  checkexpandle,
} from "../../CommonBlocks/js/CommonBlock";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";
import { iteratechannels } from "../../AddNewLevels/js/AddNewLevels";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMsal } from "@azure/msal-react";
toast.configure();

export const checkComChannels = (level, data) => {
  return level === 1 ? iteratechannels(data) : true;
};

// Minimum atleast one MOC to be selected to submit the data
const onDefaultCommChecked = (e, requestData, setRequestData) => {
  if (!e.target.checked) {
    const index = requestData.default.indexOf(e.target.name);
    requestData.default.splice(index, 1);
    setRequestData({
      ...requestData,
      default: [...Array.from(new Set(requestData.default))],
    });
  } else {
    setRequestData({
      ...requestData,
      default: [...requestData.default, e.target.name],
    });
  }
};
const postCategoryData = (
  requestData,
  finaldata,
  action,
  func,
  leveltype,
  token,
  commonprops
) => {
  if (requestData.isFinalLevel !== undefined) {
    finaldata.adminMetaData = {
      ...finaldata.adminMetaData,
      isFinalLevel: requestData.isFinalLevel,
    };
  }
  if (requestData.level === 5) {
    finaldata.adminMetaData = {
      ...finaldata.adminMetaData,
      isFinalLevel: true,
    };
  }
  if (action === "save" && finaldata.adminMetaData.subCategoryName === "") {
    toast.error("Please enter category name");
  } else {
    apicall({
      finaldata,
      func,
      ...commonprops,
      action,
      leveltype,
      token,
    });
  }
};
export const isValidCategoryName = (level, func, paretCatName, catName) => {
  if (func === "edit") {
    return paretCatName === catName && level !== 1;
  } else {
    return paretCatName === catName;
  }
};
const checkValidity = (
  level,
  parentCategoryName,
  func,
  type,
  requestData,
  getPars
) => {
  const { email, mail, phone, text, startDate, endDate, categoryname } =
    requestData;
  if (endDate < startDate) {
    toast.error("End date can't be before start date");
  } else if (
    isValidCategoryName(level, func, parentCategoryName, categoryname)
  ) {
    toast.error("Category name can't be same as parent category");
  } else {
    checkComChannels(level, { email, mail, phone, text })
      ? getPars(func, type)
      : toast.error("Atleast one mode of communication has to be selected");
  }
};

const checkPdfPage = (optType, level) => {
  if (optType === "Edit") {
    return 15;
  } else if (optType === "View") {
    return 21;
  } else if (level === 2) {
    return 9;
  } else if (level === 3) {
    return 12;
  } else if (level === 4 || level === 5) {
    return 13;
  } else {
    return 4;
  }
};

// Check if its final level
export const checkFinalLevel = (catExists, level, optType, finalLevel) => {
  if (onlyView(optType) && finalLevel) {
    return true;
  } else {
    return (
      (!catExists || level === 5 || onlyAddconditon(optType)) &&
      !onlyView(optType)
    );
  }
};
// Main Add new Permission function
function AddPermissionLevels(props) {
  const { instance, accounts } = useMsal();
  const [startDateModified, setStartDateModifed] = useState(false);
  const [endDateModified, setEndDateModifed] = useState(false);
  const id = props.category.id;
  const subCatExists =
    !!props.category.subCategory || !!props.category.hasSubLevel;
  let userData = {
    ...propcondition(props),
    id,
  };
  if (props.level <= 3) {
    userData = { ...level1props(props), ...userData };
  } else {
    userData = { ...levelcommonprops(props), ...userData };
  }
  const [requestData, setRequestData] = useState(userData);
  const handleChange = (e) => {
    setRequestData({
      ...requestData,
      [e.target.name]: e.target.value,
    });
    e.target.name === "startDate" && setStartDateModifed(true);
    e.target.name === "endDate" && setEndDateModifed(true);
  };
  const onChangeAltMail = () => {
    setRequestData({
      ...requestData,
      enableAlternateEmailId: !requestData.enableAlternateEmailId,
    });
  };
  const onInputChecked = (e) => {
    setRequestData({
      ...requestData,
      [e.target.id]: e.target.checked,
    });
  };
  const onCommunicationChecked = (name, level, value) => {
    setRequestData({
      ...requestData,
      [name]: value,
    });
  };
  const onSaveClick = (e) => {
    e.preventDefault();
    getPars("add", "save");
  };

  // On submit for Approval
  const handleSubmit = (e) => {
    e.preventDefault();
    checkValidity(
      props.level,
      props.category.subCategoryName || props.category.categoryName,
      "add",
      "submit",
      requestData,
      getPars
    );
  };

  // On modify submit
  const handleEditSubmit = (e) => {
    e.preventDefault();
    checkValidity(
      props.level,
      props.category.parentCategoryName,
      "edit",
      "update",
      requestData,
      getPars
    );
  };

  const getPars = async (func, action) => {
    const token = await tokenAndRolesCapture(instance, accounts);
    if(token != undefined){
     const username = token.idTokenClaims.name;
    const comments = props.category.comments;
    const leveltype = "category";
    const finaldata = jsondata({
      ...requestData,
      comments,
      func,
      action,
      username,
      startDateModified,
      endDateModified,
    });
    postCategoryData(requestData, finaldata, action, func, leveltype, token, {
      onClose: props.onClose,
      brand: props.brand,
      notify: props.notify,
    });
  } };
  const addcondition = onlyAddconditon(props.optionType);
  return (
    <Modal
      className="modalpopup modal-permissionlevel1"
      show={props.show}
      onHide={() => props.onClose()}
    >
      <Modal.Header closeButton>
        <p>
          {addcondition ? "Add New Permission" : editcondtion(props.optionType)}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Form
          id="form1"
          onSubmit={addcondition ? handleSubmit : handleEditSubmit}
        >
          <Row>
            <Col md={12}>
              <p>Level {props.level}</p>
            </Col>
            <HelpSection pageno={checkPdfPage(props.optionType, props.level)} />
          </Row>

          <CategorySec
            category={requestData.categoryname}
            onChange={(e) => handleChange(e)}
            status={props.category.status}
            optType={props.optionType}
          />
          <DescriptionSec
            description={requestData.description}
            onChange={(e) => handleChange(e)}
            onlyDelete={deleteOrView(props.optionType)}
          />
          {props.level === 1 && (
            <EnableEmailSec
              altEmail={requestData.enableAlternateEmailId}
              onChange={() => onChangeAltMail()}
              onlyDelete={deleteOrView(props.optionType)}
            />
          )}
          {props.level <= 3 && (
            <DefaultCommunicationModes
              email={requestData.email}
              mail={requestData.mail}
              phone={requestData.phone}
              text={requestData.text}
              default={requestData.default}
              onChecked={onCommunicationChecked}
              onDefaultCommChecked={(e) =>
                onDefaultCommChecked(e, requestData, setRequestData)
              }
              onlyDelete={deleteOrView(props.optionType)}
              level={props.level}
            />
          )}
          <CSTNote />
          <DateSec
            startDate={requestData.startDate}
            endDate={requestData.endDate}
            type={editOrDelete(props.optionType) ? datevalue : "text"}
            onChange={(e) => handleChange(e)}
            onlyDelete={onlyDeleteconditon(props.optionType)}
            onlyView={onlyView(props.optionType)}
            onlyAddconditon={addcondition}
          />
          {checkFinalLevel(
            subCatExists,
            props.level,
            props.optionType,
            props.category.isFinalLevel
          ) && (
            <FinalSelection
              onlyDelete={deleteOrView(props.optionType) || props.level === 5}
              onChecked={props.category.isFinalLevel || props.level === 5}
              onChange={(e) => onInputChecked(e)}
            />
          )}
          {checkexpandle(
            props.level,
            props.optionType,
            requestData.isExpandable
          ) && (
            <IsExpandable
              id={ExpandableId}
              onChecked={requestData.isExpandable}
              onlyDelete={deleteOrView(props.optionType)}
              onChange={(e) => onInputChecked(e)}
            />
          )}
          <CommentSec
            commentText={requestData.commentText}
            onChange={(e) => handleChange(e)}
            editOrDelete={editOrDelete(props.optionType)}
            onlyDelete={deleteOrView(props.optionType)}
            onlyView={onlyView(props.optionType)}
            approve={true}
          />
          <ButtonSec {...props} onSaveClick={onSaveClick} />
        </Form>
      </Modal.Body>
    </Modal>
  );
}
export default AddPermissionLevels;
