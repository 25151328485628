import React, { useEffect, useState } from "react";
import "../scss/AdminLogout.scss";
import { FaSignOutAlt } from "react-icons/fa";
import { useMsal } from "@azure/msal-react";
import { Modal, Row, Col, Button, Container } from "react-bootstrap";
import warningicon from "./../../../assets/warning-icon.jpg";

// Error Time Out Pop Up
const TimeOut = (props) => {
  useEffect(() => {
    const time = setTimeout(props.handleLogout, 60000);
    timer();
    return () => clearTimeout(time);
  }, []);
  function timer() {
    var sec = 60;
    function updateSec() {
      sec--;
      const selector = document.querySelector("#countdown");
      if (selector) {
        document.querySelector("#countdown").innerHTML = `&nbsp${sec}`;
      }
      if (sec === 0) {
        stopTimer();
      }
    }
    updateSec();
    var interval = setInterval(updateSec, 1000);
    function stopTimer() {
      clearInterval(interval);
    }
  }
  return (
    <Modal
      show={props.showTimeOut}
      onHide={props.handleClose}
      className="logout modalpopup"
    >
      <Modal.Body>
        <Row>
          <Col md={2} className="warning-img">
            <img src={warningicon} alt="Warning" />
          </Col>
          <Col md={10} className="warning-message">
            <p>
              <strong>Your session is about to expire!</strong>
            </p>
            <p>
              You will be logged out in
              <strong>
                <span id="countdown"></span>
              </strong>{" "}
              seconds
            </p>
            <p>Do you want to stay signed in?</p>
          </Col>
        </Row>
        <Row>
          <Container fluid className="button-options logout">
            <Button
              type="submit"
              variant="secondary"
              size="sm"
              onClick={() => {
                props.resetTimer(1740000);
                props.handleClose();
              }}
            >
              Yes, Keep me signed in
            </Button>

            <Button variant="primary" size="sm" onClick={props.handleLogout}>
              No, Sign me out
            </Button>
          </Container>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

// Logout function
function AdminLogout() {
  const { instance, accounts } = useMsal();
  const [username, setUserName] = useState("");
  const [showTimeOut, setShowTimeOut] = useState(false);
  let time;
  const handleClose = () => {
    setShowTimeOut(false);
  };

  useEffect(() => {
    accounts[0] && setUserName(accounts[0].name);
    resetTimer(1740000);
  }, [instance, accounts]);

  function resetTimer(count) {
    clearTimeout(time);
    time = setTimeout(showModal, count);
  }
  const showModal = () => {
    setShowTimeOut(true);
  };
  const handleLogout = () => {
    window.sessionStorage.clear();
    instance.logout();
  };
  return (
    <>
      <div className="admin-logout" onClick={handleLogout}>
        Hello, <strong>{username.replace("(TMNA)", "").trim()}</strong>
        &nbsp;&nbsp;
        <FaSignOutAlt />
      </div>{" "}
      {showTimeOut && (
        <TimeOut
          showTimeOut={showTimeOut}
          handleClose={handleClose}
          resetTimer={resetTimer}
          handleLogout={handleLogout}
        />
      )}
    </>
  );
}

export default AdminLogout;
