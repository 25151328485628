import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  Container,
  Button,
} from "react-bootstrap";
import "../scss/AddNewLevels.scss";
import { AxiosCreateNewPost } from "../../AxiosMethods/ApiCalls";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import Moment from "moment";
import {
  CategorySec,
  EnableEmailSec,
  CommentSec,
  FinalSelection,
  DefaultCommunicationModes,
  DateSec,
  onlyAddconditon,
  jsondata,
  jsondataForPreference,
  DescriptionSec,
  CSTNote,
  HelpSection,
  IsExpandable,
  ExpandableId,
} from "../../CommonBlocks/js/CommonBlock";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
toast.configure();
let categoryNames = [];

// Category Name cannot be duplicated
const isNamesDuplicated = () => {
  return categoryNames.some(
    (val, index) => index !== categoryNames.indexOf(val)
  );
};

const iscategoryNameValid = () => {
  return categoryNames.every((val) => val);
};
export const iteratechannels = (chnls) => {
  let isFalse = false;
  for (const ch in chnls) {
    if (chnls[ch] === true) {
      isFalse = true;
      break;
    }
  }
  return isFalse;
};

// Aleast one MOC to selected at each level that is available
// allLevelData.level === 1 to be removed if MOC should be validated till level 3
const checkChannelsSelected = (allLevelData) => {
  let atLeastOne = [];
  if (allLevelData.modeOfCommunication && allLevelData.level === 1) {
    atLeastOne = [
      ...atLeastOne,
      iteratechannels(allLevelData.modeOfCommunication),
    ];
  }
  if (allLevelData.subCategory) {
    atLeastOne = [
      ...atLeastOne,
      ...checkChannelsSelected(allLevelData.subCategory[0]),
    ];
  }
  return atLeastOne;
};

// Date Validation
const checkDateValidation = (allLevelData) => {
  let isValidDate = [];
  if (allLevelData.startDate && allLevelData.endDate) {
    const dateconditon = Moment(allLevelData.endDate) < Moment(allLevelData.startDate);
    isValidDate = [...isValidDate, dateconditon];
  }
  if (allLevelData.subCategory) {
    isValidDate = [
      ...isValidDate,
      ...checkDateValidation(allLevelData.subCategory[0]),
    ];
  }
  return isValidDate;
};
const setDefaultChannel = (allData, level, { name, checked }) => {
  if (allData.level === level) {
    const checkdefault = !!allData.default ? allData.default : [];
    if (!checked) {
      const index = allData.default.indexOf(name);
      allData.default.splice(index, 1);
      allData.default = [...Array.from(new Set(allData.default))];
    } else {
      allData.default =
        checkdefault && checkdefault.length !== 0
          ? [...allData.default, name]
          : [name];
    }
  } else {
    setDefaultChannel(allData.subCategory[0], level, { name, checked });
  }
};
const onCommChecked = (e, allData, setAllData, level) => {
  const tempcommobj = { ...allData };
  setDefaultChannel(tempcommobj, level, e.target);
  setAllData({ ...tempcommobj });
};
const searchCommChannels = (allData, level) => {
  let tempcommobj = {};
  if (allData.level === level) {
    tempcommobj = {
      ...tempcommobj,
      mail: !!allData.mail,
      email: !!allData.email,
      phone: !!allData.phone,
      text: !!allData.text,
      default: allData.default ? allData.default : [],
    };
    return tempcommobj;
  } else {
    return searchCommChannels(allData.subCategory[0], level);
  }
};
const searchIsExpand = (allData, level) => {
  let tempExpandable = {};
  if (allData.level === level) {
    tempExpandable = {
      ...tempExpandable,
      isExpandable: !!allData.isExpandable,
    };
    return tempExpandable;
  } else {
    return searchIsExpand(allData.subCategory[0], level);
  }
};
const commchannelsupdate = (allData, level, { name, value }) => {
  if (allData.level === level) {
    allData[name] = value;
  } else {
    commchannelsupdate(allData.subCategory[0], level, { name, value });
  }
};

// Common level component
const Levels = ({
  level,
  handleChange,
  onFinalChangeCategory,
  onAddBtnClick,
  onRemoveBtnClick,
  index,
  levelValue,
  isfinalLevel,
  commChannels,
  onInputChecked,
  optionType,
  altEmail,
  onChangeAltMail,
  Permission,
  onDefaultCommChecked,
  isExpandObj,
}) => {
  const length = level === levelValue.length;
  return (
    <div className={`category-common category-level${level}`}>
      <Row className="category-sec">
        <Col md={6}>
          <p>
            Level {level}
            {level === 1 && "*"}
          </p>
        </Col>
        <Col md={6}>
          <p className="plusmenu-danger">
            {length && (
              <span>
                {isfinalLevel !== level && (
                  <span
                    data-testid="plusicon"
                    style={{ marginRight: "5px" }}
                    onClick={() => onAddBtnClick(level)}
                  >
                    <FaPlusCircle />
                  </span>
                )}
                {level > 1 && (
                  <span
                    data-testid="minusicon"
                    onClick={() => onRemoveBtnClick(index, level)}
                  >
                    <FaMinusCircle />{" "}
                  </span>
                )}
              </span>
            )}
          </p>
        </Col>
      </Row>
      <CategorySec onChange={(e) => handleChange(e, level)} optType={"Add"} />
      <DescriptionSec onChange={(e) => handleChange(e, level)} />
      {level === 1 && (
        <EnableEmailSec
          altEmail={altEmail}
          onChange={() => onChangeAltMail()}
        />
      )}
      {((Permission && level <= 3) || (!Permission && level === 1)) && (
        <DefaultCommunicationModes
          email={commChannels.email}
          mail={commChannels.mail}
          phone={commChannels.phone}
          text={commChannels.text}
          default={commChannels.default}
          onDefaultCommChecked={onDefaultCommChecked}
          onChecked={onInputChecked}
          onlyDelete={optionType === "Delete"}
          level={level}
        />
      )}
      <CSTNote />
      <DateSec
        type="text"
        onChange={(e) => handleChange(e, level)}
        onlyAddconditon={onlyAddconditon("Add")}
      />
      {length && (
        <FinalSelection
          onChecked={isfinalLevel === level}
          onChange={(e) => onFinalChangeCategory(e, level)}
          onlyDelete={level === 5}
        />
      )}
      {level >= 2 && level <= 4 && (
        <IsExpandable
          id={`${ExpandableId}${level}`}
          onChecked={isExpandObj.isExpandable}
          onChange={(e) =>
            onInputChecked(ExpandableId, level, !isExpandObj.isExpandable)
          }
        />
      )}
      <CommentSec
        readonly={false}
        onChange={(e) => handleChange(e, level)}
        approve={true}
      />
    </div>
  );
};

// Adding new level with plus icon from UI
const NewLevelSec = ({
  handleChange,
  altEmail,
  onChangeAltMail,
  onInputChecked,
  addLevel,
  setFinalLevel,
  isfinalLevel,
  Permission,
  setAllData,
  allData,
}) => {
  const [levelValue, setLevelValue] = useState([]);
  useEffect(() => {
    setLevelValue([1]);
  }, [Permission]);

  const onAddBtnClick = (level) => {
    if (level < 5) {
      level + 1 === 5 && setFinalLevel(5);
      setLevelValue([...levelValue, level + 1]);
      addLevel(level + 1);
    } else {
      toast.error("Your can't create further Levels");
    }
  };

  const onRemoveBtnClick = (index, level) => {
    setFinalLevel(false);
    const values = [...levelValue];
    values.splice(index, 1);
    setLevelValue(values);
    const tempdata = { ...allData };
    removeCategoryLevel(tempdata, level);
    setAllData(tempdata);
  };

  const onFinalChangeCategory = (e, level) => {
    const finallevel = e.target.checked ? level : null;
    setFinalLevel(finallevel);
  };
  return (
    <div className="create-levels">
      {levelValue.map((level, index) => {
        let comchnls = {};
        if ((Permission && level <= 3) || (!Permission && level === 1)) {
          comchnls = searchCommChannels(allData, level);
        }
        const isExpandObj = searchIsExpand(allData, level);
        return (
          <Levels
            key={index}
            index={index}
            level={level}
            levelValue={levelValue}
            onAddBtnClick={onAddBtnClick}
            onFinalChangeCategory={onFinalChangeCategory}
            handleChange={handleChange}
            isfinalLevel={isfinalLevel}
            onRemoveBtnClick={onRemoveBtnClick}
            onChangeAltMail={onChangeAltMail}
            commChannels={comchnls}
            onInputChecked={onInputChecked}
            altEmail={altEmail}
            Permission={Permission}
            onDefaultCommChecked={(e) =>
              onCommChecked(e, allData, setAllData, level)
            }
            isExpandObj={isExpandObj}
          />
        );
      })}
    </div>
  );
};

const getResponse = (res, notfy, onClose) => {
  let resText = "";
  if (res.code === "200") {
    onClose();
    resText = res.messages[0].description;
    notfy(resText, "success");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } else {
    res.messages.map((i) => {
      resText += `${i.description}\n`;
    });
    notfy(resText, "error");
  }
};
const commonprops = (level, rank, { name, value, type, checked }) => {
  return {
    [name]: type === "checked" ? checked : value,
    level,
    rank,
  };
};
const initicalCategories = () => {
  return {
    level: 1,
    rank: 1,
    email: false,
    mail: false,
    phone: false,
    text: false,
    default: [],
    subCategory: [],
  };
};

const searchCategoryLevel = (allData, level, allprops) => {
  if (allData.level + 1 === level) {
    const tempobj = allData.subCategory[0] ? allData.subCategory[0] : {};
    allData.subCategory = [{ ...tempobj, ...allprops }];
  } else {
    searchCategoryLevel(allData.subCategory[0], level, allprops);
  }
};

// Final JSON that will be posted to backend
const finalJsonData = (
  allData,
  isfinalLevel,
  username,
  func,
  action,
  isPermission
) => {
  const { adminMetaData: tempobj } =
    isPermission || (!isPermission && allData.level === 1)
      ? jsondata({
          ...allData,
          username,
          func,
          action,
        })
      : jsondataForPreference({
          ...allData,
          username,
          func,
          action,
        });
  let subcategory = {};
  const isfinallevel = { isFinalLevel: allData.level === isfinalLevel };
  categoryNames = [...categoryNames, allData.categoryname];
  if (allData.subCategory[0]) {
    subcategory = {
      ...subcategory,
      subCategory: [
        finalJsonData(
          allData.subCategory[0],
          isfinalLevel,
          username,
          func,
          action,
          isPermission
        ),
      ],
    };
  }
  return { ...tempobj, ...subcategory, ...isfinallevel };
};
const addCategoryLevel = (alldata, level) => {
  if (alldata.level + 1 === level) {
    alldata.subCategory = [
      {
        level: level,
        rank: 1,
        subCategory: [],
      },
    ];
  } else {
    addCategoryLevel(alldata.subCategory[0], level);
  }
};
const removeCategoryLevel = (alldata, level) => {
  if (alldata.subCategory && alldata.subCategory[0].level === level) {
    alldata.subCategory = [];
  } else {
    removeCategoryLevel(alldata.subCategory[0], level);
  }
};

const validateforSubmit = (datajson) => {
  let submitValidated = true;
  if (!checkChannelsSelected(datajson).every((e) => e)) {
    toast.error("At least one mode of communication has to be selected");
    submitValidated = false;
  } else if (checkDateValidation(datajson).some((e) => e)) {
    toast.error("End date can't be before start date");
    submitValidated = false;
  } else if (isNamesDuplicated()) {
    toast.error("Category name can't be same as parent category");
    submitValidated = false;
  }
  return submitValidated;
};
const validateforSave = () => {
  let isvalid = true;
  if (!iscategoryNameValid()) {
    toast.error("Please enter category name");
    isvalid = false;
  }
  return isvalid;
};

// Main Add New Category function
function AddNewLevels(props) {
  const [allData, setAllData] = useState(initicalCategories());
  const [isfinalLevel, setFinalLevel] = useState(null);
  const [altEmail, setAltMail] = useState(false);
  const [Permission, setCategory] = useState(true);
  const [varient, setvarient] = useState({ perm: "dark", pref: "light" });
  const { instance, accounts } = useMsal();
  useEffect(() => {
    Permission
      ? setvarient({ perm: "dark", pref: "light" })
      : setvarient({ perm: "light", pref: "dark" });
    setAllData(initicalCategories());
    document.getElementById("form1").reset();
  }, [Permission]);
  const addLevel = (level) => {
    const tempobj = { ...allData };
    addCategoryLevel(tempobj, level);
    setAllData(tempobj);
  };
  const handleChangePerm = (e, level) => {
    const commnspars = {
      ...commonprops(level, 1, e.target),
    };
    const levelOnerank =
      props.rankForLevelOne > 0 ? props.rankForLevelOne + 1 : 1;
    const tempobj = { ...allData };
    if (level === 1) {
      setAllData({
        ...tempobj,
        brand: props.brand,
        enableAlternateEmailId: altEmail,
        ...commonprops(level, levelOnerank, e.target),
      });
    } else {
      searchCategoryLevel(tempobj, level, commnspars);
      setAllData(tempobj);
    }
  };
  const onInputChecked = (name, level, value) => {
    const comchannel = { name, value };
    const tempobj = { ...allData };
    commchannelsupdate(tempobj, level, comchannel);
    setAllData({ ...tempobj });
  };

  const onChangeAltMail = () => {
    setAltMail(!altEmail);
  };
  const onChangeCategory = () => {
    setCategory(!Permission);
  };

  const getPars = async (action) => {
    categoryNames = [];
    const token = await tokenAndRolesCapture(instance, accounts);
    if(token != undefined){
    const username = token.idTokenClaims.name;
    const finaldatajson = finalJsonData(
      { ...allData },
      isfinalLevel,
      username,
      "add",
      action,
      Permission
    );
    const finalData = {
      adminMetaData: finaldatajson,
    };
    const validatecond =
      action === "submit"
        ? validateforSubmit(finaldatajson)
        : validateforSave();
    if (validatecond) {
      const getMetaData = await AxiosCreateNewPost(
        {
          finalData,
          brand: props.brand,
          action: action === "submit" ? "submitAll" : "saveAll",
        },
        token
      );
      getResponse(getMetaData, props.notify, props.onClose);
    }
  }
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    getPars("submit");
  };
  const onSaveAll = async (e) => {
    e.preventDefault();
    getPars("save");
  };
  return (
    <Modal
      className="modalpopup modal-newcategory"
      show={props.show}
      onHide={() => props.onClose()}
    >
      <form id="form1" onSubmit={onFormSubmit}>
        <Modal.Header closeButton>
          <p>Add New Category</p>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={8}>
              <ToggleButtonGroup
                className="category-options"
                name="alternative-email-toggle"
                type="radio"
              >
                <ToggleButton
                  variant={varient.perm}
                  name="permission-category"
                  id="Permission"
                  checked={Permission}
                  type="radio"
                  onChange={() => onChangeCategory()}
                  value="Permission"
                  className="shadow-none"
                >
                  Permission
                </ToggleButton>

                <ToggleButton
                  variant={varient.pref}
                  name="alternative-email"
                  id="Preference"
                  type="radio"
                  checked={!Permission}
                  onChange={() => onChangeCategory()}
                  value="Preference"
                  className="shadow-none"
                >
                  Preference
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>
            <HelpSection pageno={2} />
          </Row>
          <Row className="cst-note">
            <Col>
              <p>Note: Level 1 is common for both permission and preference.</p>
            </Col>
          </Row>
          <NewLevelSec
            handleChange={handleChangePerm}
            altEmail={altEmail}
            onChangeAltMail={onChangeAltMail}
            onInputChecked={onInputChecked}
            optionType={props.optionType}
            addLevel={addLevel}
            isfinalLevel={isfinalLevel}
            setFinalLevel={setFinalLevel}
            Permission={Permission}
            setAllData={setAllData}
            allData={allData}
          />
          <Container fluid className="button-options">
            <Button variant="primary" size="sm" onClick={() => props.onClose()}>
              Cancel
            </Button>
            <Button variant="primary" size="sm" onClick={(e) => onSaveAll(e)}>
              Save for Later
            </Button>
            <Button type="submit" variant="secondary" size="sm">
              Submit for Approval
            </Button>
          </Container>
        </Modal.Body>
      </form>
    </Modal>
  );
}

export default AddNewLevels;
