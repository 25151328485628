import React from "react";

// Function to show MOC component selected and deselected on UI
function CommunicationChannel(props) {
  return (
    <span
      className={props.Checked ? "btn btn-dark" : "btn btn-light"}
      key={props.id}
      onClick={props.onChecked}
    >
      <img
        className="comm-channels"
        src={
          props.Checked
            ? `../../../assets/${props.checkedimgSrc}`
            : `../../../assets/${props.imgSrc}`
        }
        alt="communication-channel"
      />
      <p className="mode-labels">{props.desc}</p>
      <p>{props.CheckBoxText}</p>
    </span>
  );
}

export default CommunicationChannel;
