import React, { useEffect } from "react";
import DashboardAdmin from "./components/DashboardAdmin/js/DashboardAdmin";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../src/components/authconfig";
import { MsalProvider, AuthenticatedTemplate } from "@azure/msal-react";

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  useEffect(() => {
    RedirectToSign();
  }, []);
  function RedirectToSign() {
    const acounts = msalInstance.getAllAccounts();
    if (acounts.length === 0) {
      msalInstance
        .loginRedirect(loginRequest)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("Logged In");
        });
    }
  }
  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <DashboardAdmin />
      </AuthenticatedTemplate>
    </MsalProvider>
  );
}
export default App;
