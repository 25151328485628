import React from "react";
import { Modal, Row, Col, Button, Container } from "react-bootstrap";
import { AxiosPut } from "../../AxiosMethods/ApiCalls";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";
import { useMsal } from "@azure/msal-react";
import "../scss/DeleteLevel.scss";

// Api call to post delete json
export async function apicall({
  finaldata,
  notify,
  brand,
  action,
  onClose,
  level,
  token,
}) {
  const type = level === 1 ? "Category" : "subCategory";
  const postData = { finaldata, type, brand, action };
  let resText = "";
  const result = await AxiosPut(postData, token);
  if (result.code === "200") {
    onClose();
    resText = result.messages[0].description;
    notify(resText, "success");
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } else {
	  if(result.messages != undefined) {
		  result.messages.map((i) => {
		  resText += `${i.description}\n`;
		  });
		  notify(resText, "error");
	 }
  }
}

// Delete Fucntion - Delete operation possible only on Draft Records
function DeleteLevel(props) {
  const ifpref = props.category.isPreference;
  const id = props.category.id;
  const brand = props.category.brand;
  const { instance, accounts } = useMsal();
  const finalData = {
    adminMetaData: {
      id: id,
    },
  };
  const handleDelete = async () => {
    const token = await tokenAndRolesCapture(instance, accounts);
    apicall({
      finaldata: finalData,
      notify: props.notify,
      brand,
      level: props.category.level,
      onClose: props.onClose,
      action: "delete",
      token,
    });
  };
  return (
    <Modal
      className="modalpopup modal-delete"
      show={props.show}
      onHide={() => props.onClose()}
    >
      <Modal.Header closeButton>
        <p>Delete {ifpref ? "Preference" : "Permission"}</p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <h6>Are you sure you want to delete this item?</h6>
            <p className="delete-txt">
              Deleting this {ifpref ? "preference" : "permission"} will
              permanently remove all subsequent levels. Click on delete to
              proceed.
            </p>
          </Col>
        </Row>
        <Container fluid className="button-options">
          <Button variant="primary" size="sm" onClick={() => props.onClose()}>
            Cancel
          </Button>

          <Button
            type="submit"
            variant="secondary"
            size="sm"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteLevel;
