export const RolePermission = {
  "ECPC.SUPER.ADMIN": { brand: "both", read: true, write: true },
  "ECPC.TOYOTA.SUPER.ADMIN": { brand: "toyota", read: true, write: true },
  "ECPC.TOYOTA.ADMIN": { brand: "toyota", read: true, write: true },
  "ECPC.TOYOTA.APPROVER": { brand: "toyota", read: true, write: false },
  "ECPC.TOYOTA.READONLY.USER": { brand: "toyota", read: true, write: false },
  "ECPC.LEXUS.ADMIN": { brand: "lexus", read: true, write: true },
  "ECPC.LEXUS.SUPER.ADMIN": { brand: "lexus", read: true, write: true },
  "ECPC.LEXUS.APPROVER": { brand: "lexus", read: true, write: false },
  "ECPC.LEXUS.READONLY.USER": { brand: "lexus", read: true, write: false },
};
