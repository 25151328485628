import React, { useState, useEffect } from "react";
import { FormControl, Row, Col, Modal } from "react-bootstrap";
import "../scss/DetailedViewPage.scss";
import { AxiosGet } from "../../AxiosMethods/ApiCalls";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import {
  CommentSec,
  DescriptionSec,
  EnableEmailSec,
  DefaultCommunicationModes,
  FinalSelection,
  forEachComments,
  IsExpandable,
  ExpandableId,
} from "../../CommonBlocks/js/CommonBlock";
import { tokenAndRolesCapture } from "../../CommonBlocks/js/GenerateToken";
import { useMsal } from "@azure/msal-react";

const DateBlock = (props) => {
  return (
    <Row className="date-wrap">
      <Col md={5}>
        <FormControl
          type="text"
          name="fromdate"
          id="fromdate"
          placeholder="Start Date and Time*"
          value={props.startDate}
          readOnly
        />
      </Col>
      <Col md={5}>
        <FormControl
          type="text"
          name="todate"
          id="todate"
          placeholder="End Date and Time*"
          value={props.endDate}
          readOnly
        />
      </Col>
    </Row>
  );
};

const PlusMinus = ({ isExpand }) => {
  return isExpand ? <FaMinusCircle /> : <FaPlusCircle />;
};

// Component to view level 1 details
export const Level1 = (props) => {
  const [SubC1, setSubC1] = useState(false);
  const modecoms = props.modeOfCommunication;
  useEffect(() => {
    setSubC1(props.isExpandAll);
  }, [props.isExpandAll]);
  return (
    <div className="level1">
      <Row className="category-sec">
        <Col md={12}>
          <p className="plusmenu-danger" onClick={() => setSubC1(!SubC1)}>
            <PlusMinus isExpand={SubC1} />
            {props.categoryName}
          </p>
        </Col>
      </Row>
      {SubC1 && (
        <div className="sub-wrap">
          <div className="demoBoarder">
            <DescriptionSec description={props.description} onlyDelete={true} />
            <EnableEmailSec
              altEmail={props.enableAlternateEmailId}
              onlyDelete={true}
            />

            {props.level <= 3 && (
              <DefaultCommunicationModes
                email={modecoms && modecoms.email}
                mail={modecoms && modecoms.mail}
                phone={modecoms && modecoms.phone}
                text={modecoms && modecoms.text}
                default={modecoms ? modecoms.default : []}
                onlyDelete={true}
                level={props.level}
              />
            )}
            <DateBlock
              startDate={props.startDate}
              endDate={props.endDate}
              readOnly
            />
            {props.isFinalLevel && (
              <FinalSelection
                onlyDelete={true}
                onChecked={props.isFinalLevel}
              />
            )}
            <CommentSec
              commentText={
                props.comments ? forEachComments(props.comments) : ""
              }
              readOnly
            />
          </div>
          {props.subCategory &&
            props.subCategory.map((subdata, key) => {
              return (
                <LevelCommon
                  key={key}
                  {...subdata}
                  isExpandAll={props.isExpandAll}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

// Common component for repeating levels
const LevelCommon = (props) => {
  const [SubC2, setSubC2] = useState(false);
  const modecoms = props.modeOfCommunication;
  useEffect(() => {
    setSubC2(props.isExpandAll);
  }, [props.isExpandAll]);
  return (
    <div className="level2">
      <Row className="category-sec">
        <Col md={12}>
          <p className="plusmenu-danger" onClick={() => setSubC2(!SubC2)}>
            <PlusMinus isExpand={SubC2} />
            {props.subCategoryName}
          </p>
        </Col>
      </Row>
      {SubC2 && (
        <div className="sub-wrap">
          <div className="demoBoarder">
            <DescriptionSec description={props.description} onlyDelete={true} />
            {props.level <= 3 && !props.isPreference && (
              <DefaultCommunicationModes
                email={modecoms && modecoms.email}
                mail={modecoms && modecoms.mail}
                phone={modecoms && modecoms.phone}
                text={modecoms && modecoms.text}
                default={modecoms ? modecoms.default : []}
                onlyDelete={true}
                level={props.level}
              />
            )}
            <DateBlock startDate={props.startDate} endDate={props.endDate} />
            {props.isFinalLevel && (
              <FinalSelection
                onlyDelete={true}
                onChecked={props.isFinalLevel || props.level === 5}
              />
            )}
            {props.isExpandable && (
              <IsExpandable
                id={ExpandableId}
                onlyDelete={true}
                onChecked={props.isExpandable}
              />
            )}
            <CommentSec
              commentText={
                props.comments ? forEachComments(props.comments) : ""
              }
              readOnly
            />
          </div>
          {props.subCategory &&
            props.subCategory.map((subdata, pos) => {
              return (
                <LevelCommon
                  key={pos}
                  {...subdata}
                  isExpandAll={props.isExpandAll}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

// Main View Existing content fucntion
// View Exisiting content is only for Level 1
function DetailedViewPage(props) {
  const handleClose = () => props.onClose();
  const [isExpandAll, setIsExpandAll] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [detailedCategoryList, setDetailedCategoryList] = useState([]);
  const { instance, accounts } = useMsal();
  const handleExpand = () => {
    setIsExpandAll(!isExpandAll);
  };
  useEffect(() => {
    getApiCall();
  }, [props.brand]);
  const getApiCall = async () => {
    const token = await tokenAndRolesCapture(instance, accounts);
    setLoading(true);
    const getDataApi = AxiosGet(
      {
        brand: props.brand,
        type: "metaDataList",
      },
      token
    );
    getDataApi.then((result) => {
      setLoading(false);
      setDetailedCategoryList(result.data.data);
    });
  };

  return (
    <Modal
      className="modalpopup modal-detailedview"
      show={props.show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <p>Detailed View</p>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <p className="collapse-fun" onClick={() => handleExpand()}>
            {isExpandAll ? "Collapse All" : "Expand All"}
          </p>
        )}
        {isLoading && <span>Loading...</span>}
        {detailedCategoryList &&
          Array.from(detailedCategoryList).map((data, key) => {
            return (
              props.category.id === data.id && (
                <Level1 key={key} {...data} isExpandAll={isExpandAll} />
              )
            );
          })}
      </Modal.Body>
    </Modal>
  );
}

export default DetailedViewPage;
