import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import "../scss/LeftNavigation.scss";

// Left Navigation Component
function LeftNavigation() {
  const location = useLocation();
  const [SubC1, setSubC1] = useState(false);
  const [isActive, setActive] = useState(false);
  useEffect(() => {
    if (location?.pathname?.includes("report")) {
      setActive(true);
      setSubC1(true);
    }
  }, [location]);
  const handleToggle = () => {
    setActive(!isActive);
    setSubC1(!SubC1);
  };
  return (
    <Nav className="navigation-menu">
      <Nav.Item>
        <NavLink
          href="#dashboard"
          to={"/dashboard"}
          onClick={() => {
            setSubC1(false);
            setActive(false);
          }}
        >
          Dashboard
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink
          to={"/manage"}
          onClick={() => {
            setSubC1(false);
            setActive(false);
          }}
        >
          Manage Permissions/Preferences
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          className={isActive ? "right-arrow open" : "right-arrow"}
          onClick={handleToggle}
        >
          Reports
        </Nav.Link>
      </Nav.Item>
      {SubC1 && (
        <Nav className="sub-dropdown">
          <Nav.Item>
            <NavLink to={"/report-perm"}>Permission/Preference</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to={"report-audit"}>Audit Trail</NavLink>
          </Nav.Item>
        </Nav>
      )}
    </Nav>
  );
}
export default LeftNavigation;
