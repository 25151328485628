import axios from "axios";
const contenttype = "application/json";
const { REACT_APP_API_URL } = process.env;

// POST API Call to post the json to backend
export const AxiosPost = async (props, token) => {
  const response = await axios.post(
    `${REACT_APP_API_URL}/admin-meta-data/${props.brand}?action=${props.action}&type=${props.type}`,
    JSON.stringify(props.finaldata),
    {
      headers: {
        "Content-type": contenttype,
        Authorization: `Bearer ${token.idToken}`,
      },
    }
  );
  return response ? response.data.status : "";
};

// GET API Call to get the data on Manage Preference page
export const AxiosGet = (props, token) => {
  return axios.get(
    `${REACT_APP_API_URL}/admin-meta-data/${props.brand}?fetch=${props.type}`,
    {
      headers: {
        Authorization: `Bearer ${token.idToken}`,
      },
    }
  );
};

// API Call to modify or delete any records
export const AxiosPut = async (props, token) => {
  const response = await axios.put(
    `${REACT_APP_API_URL}/admin-meta-data/${props.brand}?action=${props.action}&type=${props.type}`,
    JSON.stringify(props.finaldata),
    {
      headers: {
        "Content-type": contenttype,
        Authorization: `Bearer ${token.idToken}`,
      },
    }
  );
  return response ? response.data.status : "";
};

// POST API Call to post the id of record and get all the needed details
export const AxiosPostMetadata = async (props, token) => {
  const response = await axios.post(
    `${REACT_APP_API_URL}/admin-meta-data/${props.adminMetaData.brand}?action=getMetaDataDetails`,
    JSON.stringify(props),
    {
      headers: {
        "Content-type": contenttype,
        Authorization: `Bearer ${token.idToken}`,
      },
    }
  );
  return response.data;
};

// POST call to submit multiple levels from Create New Pop up
export const AxiosCreateNewPost = async (props, token) => {
  const response = await axios.post(
    `${REACT_APP_API_URL}/admin-meta-data/${props.brand}?action=${props.action}`,
    JSON.stringify(props.finalData),
    {
      headers: {
        "Content-type": contenttype,
        Authorization: `Bearer ${token.idToken}`,
      },
    }
  );
  return response ? response.data.status : "";
};
